import {FETCH_PROJECTS_START, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_ERROR} from '../actions/actionTypes'
import firebase from '../../axios/firebase'

export default function fetchProjects() {
    return async dispatch => {
        dispatch(fetchProjectsStart())
        try {
            firebase.database().ref('projects').once('value').then(function(snapshot){
                let projects = snapshot.val()
                projects.map((project, index) => {
                    firebase.storage().ref().child('projects/short-img-' + index + '.png').getDownloadURL().then(function(url) {
                        project['shortImgUrl'] = url
                    })
                    firebase.storage().ref().child('projects/medium-img-' + index + '.png').getDownloadURL().then(function(url) {
                        project['mediumImgUrl'] = url
                    })
                    firebase.storage().ref().child('projects/big-img-' + index + '.png').getDownloadURL().then(function(url) {
                        project['bigImgUrl'] = url
                    })
                    if (index === 0) {
                        firebase.storage().ref().child('projects/medium-img-0-title.png').getDownloadURL().then(function(url) {
                            project['titleUrl'] = url
                        })
                    }
                    return project
                })
                setTimeout(function() {
                    dispatch(fetchProjectsSuccess(projects));
                }, 1000); 
            });
        } catch (e) {
            dispatch(fetchProjectsError(e))
        }
    }
}

export function fetchProjectsStart () {
    return {
        type: FETCH_PROJECTS_START
    }
}

export function fetchProjectsSuccess (projects) {
    return {
        type: FETCH_PROJECTS_SUCCESS,
        projects
    }
}

export function fetchProjectsError (e) {
    return {
        type: FETCH_PROJECTS_ERROR,
        error: e
    }
}