import {FETCH_SOLUTIONS_START, FETCH_SOLUTIONSUKR_SUCCESS, FETCH_SOLUTIONS_SUCCESS, FETCH_SOLUTIONS_ERROR} from '../actions/actionTypes'
import firebase from '../../axios/firebase'

export default function fetchSolutions() {
    return async dispatch => {
        dispatch(fetchSolutionsStart())
        try {
            firebase.database().ref('solutions').once('value').then(function(snapshot){
                let solutions = snapshot.val()
                dispatch(fetchSolutionsSuccess(solutions))
            });
            firebase.database().ref('solutionsUkr').once('value').then(function(snapshot){
                let solutions = snapshot.val()
                dispatch(fetchSolutionsUkrSuccess(solutions))
            });
        } catch (e) {
            dispatch(fetchSolutionsError(e))
        }
    }
}

export function fetchSolutionsStart () {
    return {
        type: FETCH_SOLUTIONS_START
    }
}

export function fetchSolutionsSuccess (solutions) {
    return {
        type: FETCH_SOLUTIONS_SUCCESS,
        solutions
    }
}

export function fetchSolutionsUkrSuccess (solutions) {
    return {
        type: FETCH_SOLUTIONSUKR_SUCCESS,
        solutions
    }
}

export function fetchSolutionsError (e) {
    return {
        type: FETCH_SOLUTIONS_ERROR,
        error: e
    }
}