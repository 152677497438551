import {ADMIN_SET_STATE} from '../actions/actionTypes'

const initialState = {
    isAdminPage: false,
}

export default function adminPageReducer(state = initialState, action) {
    switch (action.type) {
        case ADMIN_SET_STATE:
            return {
                ...state,
                isAdminPage: action.isAdmin
            }
        default: 
        return state
    }
}