import {CONTACTS_SET_STATE} from '../actions/actionTypes'

const initialState = {
    isContactsPage: false,
}

export default function contactsPageReducer(state = initialState, action) {
    switch (action.type) {
        case CONTACTS_SET_STATE:
            return {
                ...state,
                isContactsPage: action.isContacts
            }
        default: 
        return state
    }
}