import {SHOW_MODAL} from '../actions/actionTypes'

const initialState = {
    modal: false,
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modal: action.modal
            }
        default: 
        return state
    }
}