import {CONTACTS_SET_STATE} from './actionTypes'

export function onContactsSetState(isContacts) {
    return {
        type: CONTACTS_SET_STATE,
        isContacts
    }
}

export default function onContacts(isContacts) {
    return (dispatch) => {
        dispatch(onContactsSetState(isContacts))
    }
}