import {FETCH_SERVICES_START, FETCH_SERVICESUKR_SUCCESS, FETCH_SERVICES_SUCCESS, FETCH_SERVICES_ERROR} from '../actions/actionTypes'

const initialState = {
    services: [],
    servicesUkr: [],
    error: null,
    menu: false,
    loading: true
}

export default function servicesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SERVICES_START: 
        return {
            ...state,
            loading: true
        }
        case FETCH_SERVICES_SUCCESS: 
        return {
            ...state, 
            services: action.services,
            loading: false
        }
        case FETCH_SERVICESUKR_SUCCESS: 
        return {
            ...state, 
            servicesUkr: action.services,
            loading: false
        }
        case FETCH_SERVICES_ERROR: 
        return {
            ...state,
            error: action.error,
            loading: true
        }
        default: 
        return state
    }
}