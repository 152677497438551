import {FETCH_PROJECTS_START, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_ERROR} from '../actions/actionTypes'

const initialState = {
    projects: [],
    error: null,
    loading: true
}

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PROJECTS_START: 
        return {
            ...state,
            loading: true
        }
        case FETCH_PROJECTS_SUCCESS: 
        return {
            ...state, 
            loading: false,
            projects: action.projects
        }
        case FETCH_PROJECTS_ERROR: 
        return {
            ...state,
            error: action.error,
            loading: true
        }
        default: 
        return state
    }
}