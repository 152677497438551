import React from 'react'
import classes from './GetCallBack.module.scss'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'

const GetCallBack = (props) => (
    <div className={classes.GetCallBack}>
        <p className={classes.title}>{props.language === 'RU' ? 'заказать энергоаудит' : 'замовити енергоаудит'}</p>
        <NavLink
            to={'/contacts'}
            exact={false}
            className={classes.link}
            >
                {props.language === 'RU' ? 'Оставить заявку' : 'Залишити заявку'}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 17L17 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10 7H17V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
        </NavLink>
    </div>
)
function mapStateToProps(state) {
    return {
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetCallBack)