import {FETCH_NEWS_START, FETCH_NEWS_SUCCESS, FETCH_NEWS_ERROR, CHANGE_PAGE} from '../actions/actionTypes'
import firebase from '../../axios/firebase'

export default function fetchNews() {
    return async dispatch => {
        dispatch(fetchNewsStart())
        try {
            firebase.database().ref('news').once('value').then(function(snapshot){
                let news = snapshot.val()
                Object.entries(news).forEach((item, index) => {
                    firebase.storage().ref().child('news/short-img-' + item[0] + '.png').getDownloadURL().then(function(url) {
                        item[1]['shortImgUrl'] = url
                    })
                    firebase.storage().ref().child('news/medium-img-' + item[0] + '.png').getDownloadURL().then(function(url) {
                        item[1]['mediumImgUrl'] = url
                    })
                    firebase.storage().ref().child('news/title-img-' + item[0] + '.png').getDownloadURL().then(function(url) {
                        item[1]['titleImgUrl'] = url
                    })
                    firebase.storage().ref().child('news/big-img-' + item[0] + '-0.png').getDownloadURL().then(function(url) {
                        item[1]['bigImgUrl1'] = url
                    })
                    firebase.storage().ref().child('news/big-img-' + item[0] + '-1.png').getDownloadURL().then(function(url) {
                        item[1]['bigImgUrl2'] = url
                    })
                    return news
                })
                setTimeout(function() {
                    dispatch(fetchNewsSuccess(news));
                }, 1500); 
            });
        } catch (e) {
            dispatch(fetchNewsError(e))
        }
    }
}

export function fetchNewsStart () {
    return {
        type: FETCH_NEWS_START
    }
}

export function fetchNewsSuccess (news) {
    return {
        type: FETCH_NEWS_SUCCESS,
        news
    }
}


export function fetchNewsError (e) {
    return {
        type: FETCH_NEWS_ERROR,
        error: e
    }
}

export function onChangePage(currentPage) {
    return {
        type: CHANGE_PAGE,
        currentPage
    }
}

export function changePage(currentPage) {
    return async (dispatch) => {
        dispatch(onChangePage(currentPage))
    }
}