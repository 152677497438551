import React, {Component} from 'react'
import classes from '../Sertificat/Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class Energoeffect extends Component {
    openModal() {
        this.props.setModal(true)
    }

    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'РАЗДЕЛ «ЭНЕРГОЭФФЕКТИВНОСТЬ»' : 'РОЗДІЛ «ЕНЕРГОЕФЕКТИВНІСТЬ»'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'РАЗРАБОТКА РАЗДЕЛА «ЭНЕРГОЭФФЕКТИВНОСТЬ ОТ E-SAVE ЭТО:' : 'РОЗРОБКА РОЗДІЛУ «ЕНЕРГОЕФЕКТИВНІСТЬ» ВІД E–SAVE ЦЕ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon11.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ВЫПОЛНЕНИЕ В КРАТЧАЙШИЕ СРОКИ' : 'виконання в найкоротші терміни'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon10.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ГАРАНТИРОВАННОЕ ПРОХОЖДЕНИЕ ЭКСПЕРТИЗЫ' : 'гарантоване проходження експертизи'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon1.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'СОБЛЮДЕНИЯ ВСЕХ ТРЕБОВАНИЙ ДБН И ДСТУ' : 'дотримання усіх вимог дбн і дсту'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed3}>
                        <div className={classes.ls}>
                        <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ЧТО ВАЖНО ЗНАТЬ ПРО РАЗДЕЛ «ЭНЕРГОЭФФЕКТИВНОСТЬ»' : `ЩО ВАЖЛИВО ЗНАТИ ПРО РОЗДІЛ «Енергоефективність»`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Раздел разрабатывают аттестованные эксперты или проектные организации' : `Розділ розробляють атестовані експерти або проектні організації`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Раздел является составной частью проектной документации' : 'Розділ є складовою частиною проектної документації'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Энергопаспорт является неотъемлемой частью раздела' : `Енергопаспорт є невід'ємною частиною розділу`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'В разделе обязательно проводится оценка тепловлагосного режима ограждающих конструкций' : `У розділі обов'язково проводиться оцінка тепловлагосного режиму огороджувальних конструкцій`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'В разделе обязательно рассчитываются температуры внутренних поверхностей конструкций на основании построенных двумерных и трехмерных температурных полей' : `У розділі обов'язково розраховуються температури внутрішніх поверхонь конструкцій на підставі побудованих двовимірних і тривимірних температурних полів`}</p>
                            <p className={classes.textBold}>{this.props.language === 'RU' ? 'Командой сертифицированных специалистов E-SAVE было выполнено более 80-ти разделов "Энергоэффективность", которые успешно прошли экспертизу.' : 'Командою сертифікованих фахівців E-SAVE було виконано більше 80-ти розділів "Енергоефективність", які успішно пройшли експертизу.'}</p>
                        </div>
                        <div className={classes.rs}>
                        <img src={require('../../../img/sertif/banner4.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                           {this.props.language === 'RU' ? 'Заказать раздел' : 'Замовити роздiл'}
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'Нам доверяют:' : 'Нам довiряють:'}</h2>
                            <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={11}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 4}
                            step={1}
                            infinite={true}
                            >
                                <Slider>
                                    <Slide index={0}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/1.png')} />
                                    </Slide>
                                    <Slide index={1}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/2.png')} />
                                    </Slide>
                                    <Slide index={2}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/3.png')} />
                                    </Slide>
                                    <Slide index={3}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/4.png')} />
                                    </Slide>
                                    <Slide index={4}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/5.png')} />
                                    </Slide>
                                    <Slide index={5}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/6.png')} />
                                    </Slide>
                                    <Slide index={6}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/7.png')} />
                                    </Slide>
                                    <Slide index={7}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/8.png')} />
                                    </Slide>
                                    <Slide index={8}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/9.png')} />
                                    </Slide>
                                    <Slide index={9}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/10.png')} />
                                    </Slide>
                                    <Slide index={10}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/11.png')} />
                                    </Slide>
                                </Slider>
                                <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                                <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                            </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Energoeffect)