import React, {Component} from 'react'
import {connect} from 'react-redux'
import Fade from 'react-reveal/Fade';
import onMain from '../../redux/actions/mainPage'
import AboutMain from '../../components/AboutMain/AboutMain'
import NewsMain from '../../components/NewsMain/NewsMain'
import Sertificate from '../../components/Sertificate/Sertificate'
import  fetchSolutions  from '../../redux/actions/solutions'
import  fetchNews  from '../../redux/actions/news'
import { changePage } from '../../redux/actions/news'
import setModal from '../../redux/actions/modal'
import Modal from '../../components/UI/Modal/Modal'

import {NavLink} from 'react-router-dom'

class MainPage extends Component {

    componentDidMount () {
        this.props.onMain(true)
        this.props.fetchSolutions()
        this.props.fetchNews()
    }

    componentWillUnmount() {
        this.props.onMain(false)
    }

    openModal() {
        this.props.setModal(true)
    }

    renderSolutionsLinks() {
        let solution = this.props.solutions
        if (this.props.language === 'RU') {
            return solution.solutions.map((service, index) => {
                return (
                    <NavLink
                    key={index}
                    to={'/services/page' + index}
                    exact={false}
                    >
                        {service.name}
                    </NavLink>
                )
            })
        } else {
            return solution.solutionsUkr.map((service, index) => {
                return (
                    <NavLink
                    key={index}
                    to={'/services/page' + index}
                    exact={false}
                    >
                        {service.name}
                    </NavLink>
                )
            })
        }
    }

    renderNews(props, classes, index) {
        let news = props.news.news
        let slide = Object.entries(news).reverse()
        function renderTitle () {
            let titleNews = slide[0][1]
            return (
                <NavLink 
                    to={'/news/page' + slide[0][0]}
                    exact={false}
                >
                <div className={classes.title}>
                    <img alt='subtitle' src={titleNews.titleImgUrl}/>
                    <div className={classes.textData}>
                        <p className={classes.date}>{titleNews.date}</p>
                        <div className={classes.esave}>
                            <p>E-SAVE </p>
                            
                                {props.language === 'RU' ? titleNews.title : titleNews.Ukr.title}
                            
                        </div>
                    </div>
                </div>
                </NavLink>
            )
        }
        function renderColNews () {
            return slide.map((item, index) => {
                if (index > 2 && index < 7) {
                    return (
                        <NavLink  
                            key={index}
                            to={'/news/page' + item[0]}
                            exact={false}
                        >
                        <div className={classes.textData}>
                            <p className={classes.date}>{item[1].date}</p>
                            <div className={classes.esave}>
                                <p>E-SAVE </p>
                                
                                    {props.language === 'RU' ? item[1].title : item[1].Ukr.title}
                            </div>
                        </div>
                        </NavLink>
                    )
                }
            })
            
        }
        function renderSubTitle () {
            if (slide.length > 1) {
            return (
                <div className={classes.subTitles}>
                    <NavLink 
                        to={'/news/page' + slide[1][0]}
                        exact={false}
                    >
                    <div className={classes.subTitle}>
                        <img alt='subtitle' src={slide[1][1].titleImgUrl}/>
                        <div className={classes.textData}>
                            <p className={classes.date}>{slide[1][1].date}</p>
                            <div className={classes.esave}>
                                <p>E-SAVE </p>
                                
                                    {props.language === 'RU' ? slide[1][1].title : slide[1][1].Ukr.title}
                                
                            </div>
                        </div>
                    </div>
                    </NavLink>
                    {slide.length > 2 ?
                        <NavLink 
                        to={'/news/page' + slide[2][0]}
                        exact={false}
                    >
                    <div className={classes.subTitle}>
                        <img alt='subtitle' src={slide[2][1].titleImgUrl}/>
                        <div className={classes.textData}>
                            <p className={classes.date}>{slide[2][1].date}</p>
                            <div className={classes.esave}>
                                <p>E-SAVE </p>
                                
                                    {props.language === 'RU' ? slide[2][1].title : slide[2][1].Ukr.title}
                                
                            </div>
                        </div>
                    </div>
                    </NavLink>
                    : null
                    }
                </div>
            )} else {return null}
        }
        return (
            <div className={classes.news}> 
                <div className={classes.ls}>
                    <div className={classes.row}>
                        {renderTitle()}
                    </div>
                    <div className={classes.row}>
                        {renderSubTitle()}
                    </div>
                </div>
                <div className={classes.rs}>
                        {renderColNews ()}
                </div>
                <NavLink
                    to={'/news'}
                    exact={false}
                    className={classes.linkToNews}
                    >
                        {props.language === 'RU' ? 'Все новости' : 'Усi новини'}
                </NavLink>
            </div>
        )
    }

    render () {
        return (
            <div>
                <Fade>
                    <AboutMain 
                    language={this.props.language}
                    openModal={this.openModal.bind(this)}
                    />
                </Fade>
                <Fade>
                    <NewsMain 
                    news={this.props.news}
                    renderNews={this.renderNews}
                    language={this.props.language}
                    />
                </Fade>
                <Fade>
                    <Sertificate 
                    language={this.props.language}
                    />
                </Fade>
                <Modal />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isMainPage: state.mainPage.isMainPage,
        solutions: state.solutions,
        news: state.news,
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onMain: (isMain) => dispatch(onMain(isMain)),
        fetchSolutions: () => dispatch(fetchSolutions()),
        fetchNews: () => dispatch(fetchNews()),
        changePage: (currentPage) => dispatch(changePage(currentPage)),
        setModal: (modal) => dispatch(setModal(modal)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)