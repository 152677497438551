import {MAIN_SET_STATE} from './actionTypes'

export function onMainSetState(isMain) {
    return {
        type: MAIN_SET_STATE,
        isMain
    }
}

export default function onMain(isMain) {
    return (dispatch) => {
        dispatch(onMainSetState(isMain))
    }
}