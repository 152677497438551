import {CHANGE_NEWS, CHANGE_IMG, RESET_NEWS_CHANGING} from '../actions/actionTypes'

const initialState = {
    news: [],
    img: []
}

export default function changeNews(state = initialState, action) {
    switch (action.type) {
        case CHANGE_NEWS:
            return {
                ...state, 
                news: action.item
            }
        case CHANGE_IMG:
        return {
            ...state, 
            img: action.img
        }
        case RESET_NEWS_CHANGING:
        return {
            ...state, 
            news: [],
            img: []
        }
        default: 
            return state
    }
}