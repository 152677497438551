import {FETCH_NEWS_START, FETCH_NEWS_SUCCESS, FETCH_NEWS_ERROR, CHANGE_PAGE} from '../actions/actionTypes'

const initialState = {
    news: [],
    error: null,
    loading: true,
    currentPage: 0
}

export default function newsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_NEWS_START: 
        return {
            ...state,
            loading: true
        }
        case FETCH_NEWS_SUCCESS: 
        return {
            ...state, 
            news: action.news,
            loading: false
        }
        case FETCH_NEWS_ERROR: 
        return {
            ...state,
            error: action.error,
            loading: true
        }
        case CHANGE_PAGE: 
        return {
            ...state,
            currentPage: action.currentPage
        }
        default: 
        return state
    }
}