import {SHOW_MODAL} from './actionTypes'

export function Modal(modal) {
    return {
        type: SHOW_MODAL,
        modal
    }
}

export default function setModal(modal) {
    return (dispatch) => {
        dispatch(Modal(modal))
    }
}