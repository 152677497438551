import React, {Component} from 'react'
import Layout from './hoc/Layout/Layout'
import {Router, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import MainPage from './containers/MainPage/MainPage'
import Services from './containers/Services/Services'
import Solutions from './containers/Solutions/Solutions' 
import Contacts from './containers/Contacts/Contacts'
import About from './containers/About/About'
import Projects from './containers/Projects/Projects'
import NewsMain from './containers/NewsMain/NewsMain'
import Sertificat from './components/Service/Sertificat/Sertificat'
import EnergoEffect from './components/Service/Energoeffect/Energoeffect'
import Passport from './components/Service/Passport/Passport'
import Audit from './components/Service/Audit/Audit'
import OSBB from './components/Solution/OSBB/OSBB'
import OTG from './components/Solution/OTG/OTG'
import Developer from './components/Solution/Developer/Developer'
import Project from './containers/Project/Project'
import News from './containers/News/News'
import Auth from './containers/Auth/Auth'
import AdminPanel from './containers/AdminPanel/AdminPanel'
import CreateNews from './components/CreateNews/CreateNews'
import ChangeNews from './components/ChangeNews/ChangeNews'
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { createBrowserHistory as createHistory } from 'history'
import { autoLogin } from './redux/actions/auth'


const tagManagerArgs = {
  gtmId: ' GTM-MWGD3P2'
}
TagManager.initialize(tagManagerArgs)


const history = createHistory()
ReactGA.initialize('UA-192159756-1')
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
  console.log(location.pathname) 
});

class App extends Component {

  componentDidMount () {
    this.props.autoLogin()
    this.initGA()
  }

  initGA = () => {
    ReactGA.initialize('UA-192159756-1')
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render () {
    return (
      <Router history={history}>
      <Layout>
        <Switch>
          <Route path='/' exact component={()=><MainPage/>} />
          <Route path='/about' exact component={()=><About/>} />
          <Route path='/news' exact component={()=><NewsMain/>} />
          {/* <Route path='/projects' exact component={()=><Projects/>} /> */}
          <Route path='/news/page:id' component={props=><News {...props}/>} />
          <Route path='/services/page:id' component={props=><Services {...props}/>} />
          <Route path='/solutions/page:id' component={props=><Solutions {...props}/>} />
          {/* <Route path='/projects/page:id' component={props=><Project {...props}/>} /> */}
          <Route path='/contacts' exact component={props=><Contacts {...props}/>} />
          <Route path='/sertificat' exact component={props=><Sertificat {...props}/>} />
          <Route path='/energoeffect' exact component={props=><EnergoEffect {...props}/>} />
          <Route path='/passport' exact component={props=><Passport {...props}/>} />
          <Route path='/audit' exact component={props=><Audit {...props}/>} />
          <Route path='/osbb' exact component={props=><OSBB {...props}/>} />
          <Route path='/otg' exact component={props=><OTG {...props}/>} />
          <Route path='/developer' exact component={props=><Developer {...props}/>} />
          <Route path='/auth' exact component={()=><Auth/>} />
          { this.props.isLogged === true
          ?
          <>
          <Route path='/adminPanel' exact component={()=><AdminPanel/>} />
          <Route path='/adminPanel/createNews' component={props=><CreateNews  {...props}/>} />
          <Route path='/adminPanel/changeNews:id' component={props=><ChangeNews  {...props}/>} />
          </>
          : 
          null
          }
        </Switch>
      </Layout>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
      isLogged: !!state.auth.token
  }
}

function mapDispatchToProps(dispatch) {
  return {
      autoLogin: () => dispatch(autoLogin()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
