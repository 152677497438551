import React, {Component} from 'react'
import classes from './Contacts.module.scss'
import axios from 'axios'
import {connect} from 'react-redux'
import onContacts from '../../redux/actions/contactsPage'
const API_PATH = 'https://esave.com.ua/mail.php'

class Contacts extends Component {

    componentDidMount () {
        this.props.onContacts(true)

        const anchor = this.props.location.hash;

        if (anchor) {
            const domElement = document.querySelector(anchor);
            if (domElement) {
                domElement.scrollIntoView();
            }
        }
        // this.telValid()
    }

    componentWillUnmount() {
        this.props.onContacts(false)
    }

    state = {
        name: null, 
        phone: null
    }

    telValid = () => {
        window.addEventListener("DOMContentLoaded", function() {
            function setCursorPosition(pos, elem) {
                elem.focus();
                if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
                else if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.collapse(true);
                    range.moveEnd("character", pos);
                    range.moveStart("character", pos);
                    range.select()
                }
            }
            
            function mask(event) {
                var matrix = "+38 (___) ___ ____",
                    i = 0,
                    def = matrix.replace(/\D/g, ""),
                    val = this.value.replace(/\D/g, "");
                if (def.length >= val.length) val = def;
                this.value = matrix.replace(/./g, function(a) {
                    return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
                });
                if (event.type === "blur") {
                    if (this.value.length === 2) this.value = ""
                } else setCursorPosition(this.value.length, this)
            };
                var input = document.querySelector("#form_tel");
                input.addEventListener("input", mask, false);
                input.addEventListener("focus", mask, false);
                input.addEventListener("blur", mask, false);
            });
    }
    

    onChangeHandler = (event) => {
        console.log(event.target.name, event.target.value)
        const name = event.target.name
        const value = event.target.value
        this.setState ({
            [name]: value
        })
    }

    submitHandler = event => {
        event.preventDefault()
        console.log(this.state)
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
          })
            .then(
                this.refs.getform.reset()
            )
            .then(
                alert('Ваша заявка успешно отправлена!')
            )
            .catch(error => this.setState({ error: error.message }));
    }

    

    render () {
        return (
            <div className={classes.Contacts}>
                <div className='wrapper'>
                    <div className={classes.getCallBack} id='form' ref='form'>
                        <div className={classes.ls}>
                            {/* <h1 className={classes.title}>оставить заявку</h1> */}
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.headTitle}>{this.props.language === 'RU' ? 
                            'Связаться с нами' : 
                            `Зв'язатися з нами`
                            }</p>
                            <form className={classes.form} onSubmit={this.submitHandler} ref='getform'>
                                
                                <p className={classes.title}>{this.props.language === 'RU' ? 
                                'Ваши контакты' : 
                                'Вашi контакти'
                                }</p>
                                <section className={classes.textForm}>
                                    <input required name='name' type='text' placeholder={this.props.language === 'RU' ? 'Имя' : `Ім'я`} onChange={event => this.onChangeHandler(event)}/>
                                    <input required name='phone' type='tel' id='form_tel' placeholder='Телефон' onChange={event => this.onChangeHandler(event)}/>
                                </section>
                                <button class='contact__btn' type='submit'>{this.props.language === 'RU' ? 
                                'Отправить' : 
                                'Вiдправити'
                                }</button>
                            </form>
                        </div>
                    </div>
                    <div className={classes.contact}  id='contacts' ref='contacts'>
                        <div className={classes.ls}>
                            <h2 className={classes.title}>{this.props.language === 'RU' ? 
                            'Контакты' : 
                            'Контакти'
                            }</h2>
                        </div>
                        <div className={classes.rs}>
                            <div className={classes.contactsBlock}>
                                <div className={classes.block}>
                                    <p className={classes.titleText}>{this.props.language === 'RU' ? 
                                    'Телефоны' : 
                                    'Телефони'
                                    }</p>
                                    <a className={classes.phone + ' phone1'} href='tel:+380678080082'>+38 067 808 00 82</a>
                                    <a className={classes.phone} href='tel:+380938080082'>+38 093 808 00 82</a>
                                </div>
                                <div className={classes.block}>
                                    <p className={classes.titleText}>Почта</p>
                                    <a className={classes.mail} href='mailto:office@esave.com.ua'>office@esave.com.ua</a>
                                </div>
                                <div className={classes.block}>
                                    <p className={classes.titleText}>{this.props.language === 'RU' ? 
                                    'Адрес' : 
                                    'Адреса'
                                    }</p>
                                    <p className={classes.adress} style={{textTransform: 'none'}}>
                                    {this.props.language === 'RU' ? 
                                    '03164, г. Киев, ул. Олевская 5' : 
                                    '03164, м. Київ, вул. Олевська 5'
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isContactsPage: state.contactsPage.isContactsPage,
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onContacts: (isContacts) => dispatch(onContacts(isContacts))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)