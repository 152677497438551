import React, {Component} from 'react'
import Solution from '../../components/Solution/Solution'
import Loader from '../../components/UI/Loader/Loader'
import  fetchSolutions  from '../../redux/actions/solutions'
import {connect} from 'react-redux'

class Solutions extends Component {

    componentDidMount () {
        this.props.fetchSolutions()
    }

    render () {
        return (
            <>
                {
                    this.props.solutions.loading
                    ?
                    <Loader />
                    :
                    <Solution 
                    id={[this.props.match.params.id]}
                    props={this.props.solutions.solutions[this.props.match.params.id]}
                    propsUkr={this.props.solutions.solutionsUkr[this.props.match.params.id]}
                    language={this.props.language}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        solutions: state.solutions,
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSolutions: () => dispatch(fetchSolutions()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Solutions)