import React, {Component} from 'react'
import classes from '../Sertificat/Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class Passport extends Component {
    openModal() {
        this.props.setModal(true)
    }
    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'ЭНЕРГОПАСПОРТ' : 'ЕНЕРГОПАСПОРТ'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'РАЗРАБОТКА ЭНЕРГОПАСПОРТА ОТ E-SAVE ЭТО:' : 'РОЗРОБКА ЕНЕРГОПАСПОРТУ ВІД E-SAVE ЦЕ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon3.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'РАСЧЕТЫ ОТ АТТЕСТОВАННЫХ ЭКСПЕРТОВ' : 'РОЗРАХУНКИ ВІД АТЕСТОВАНИХ ЕКСПЕРТІВ'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon12.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? `БЫСТРОЕ ВЫПОЛНЕНИЕ \n(ДО 5-ТИ РАБОЧИХ ДНЕЙ)` : `ШВИДКЕ ВИКОНАННЯ \n(ДО 5-ТИ РОБОЧИХ ДНІВ)`}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon1.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? `СОБЛЮДЕНИЕ ВСЕХ \n ТРЕБОВАНИЙ ДБН И ДСТУ` : `дотримання усіх вимог\n дбн і дсту`}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed3}  >
                        <div className={classes.ls}>
                        <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ЧТО ВАЖНО ЗНАТЬ ПРО ЭНЕРГОПАСПОРТ ?' : `ЩО ВАЖЛИВО ЗНАТИ ПРО ЕНЕРГОПАСПОРТ?`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Энергетический паспорт составляют во время разработки проектов жилых и общественных зданий при новом строительстве, реконструкции или капитальном ремонте с целью определения расчетных показателей энергетических характеристик объектов строительства и для проведения оценки соответствия показателей установленным минимальным требованиям ДБН В.2.6-31 к энергетической эффективности зданий.' : `Енергетичний паспорт складають під час розробки проектів житлових і громадських будівель при новому будівництві, реконструкції або капітального ремонту з метою визначення розрахункових показників енергетичних характеристик об'єктів будівництва та для проведення оцінки відповідності показників встановленим мінімальним вимогам ДБН В.2.6-31 до енергетичної ефективності будівель.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Энергетический паспорт здания является составной частью паспорта объекта строительства, принятого в эксплуатацию.' : `Енергетичний паспорт будівлі є складовою частиною паспорту об'єкта будівництва, прийнятого в експлуатацію.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Для жилых многоквартирных домов с помещениями общественного назначения энергопаспорта составляют отдельно для жилой части и каждого общественного блока.' : `Для житлових багатоквартирних будинків з приміщеннями громадського призначення енергопаспорт складають окремо для житлової частини і кожного громадського блоку.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Класс энергетической эффективности здания и инженерных систем при новом строительстве должен быть не ниже «С»' : `Клас енергетичної ефективності будівлі та інженерних систем при новому будівництві повинен бути не нижче «С»`}</p>
                        </div>
                        <div className={classes.rs + ' ' + classes.lessSide}>
                        <img src={require('../../../img/sertif/banner5.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <p className={classes.titleSide} style={{margin: 20 + 'px ' + 0}}>{this.props.language === 'RU' ? 'Выполнено энергопаспортов:' : `Виконано енергопаспортiв:`}</p>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon13.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'для нового строительства' : 'ДЛЯ НОВОГО БУДІВНИЦТВА'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon14.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'при реконструкции и капитальном ремонте' : 'ПРИ РЕКОНСТРУКЦІЇ ТА КАПІТАЛЬНОГО РЕМОНТУ'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon15.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? `по фактическому состоянию \n(при энергоаудите)` : `ЗА ФАКТИЧНИМ СТАНОМ \n(ПІД ЧАС ЕНЕРГОАУДИТУ)`}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                             {this.props.language === 'RU' ? 'Заказать энергопаспорт' : 'Замовити енергопаспорт'}
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'Нам доверяют:' : 'Нам довiряють:'}</h2>
                            <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={11}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 4}
                            step={1}
                            infinite={true}
                            >
                                <Slider>
                                    <Slide index={0}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/1.png')} />
                                    </Slide>
                                    <Slide index={1}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/2.png')} />
                                    </Slide>
                                    <Slide index={2}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/3.png')} />
                                    </Slide>
                                    <Slide index={3}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/4.png')} />
                                    </Slide>
                                    <Slide index={4}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/5.png')} />
                                    </Slide>
                                    <Slide index={5}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/6.png')} />
                                    </Slide>
                                    <Slide index={6}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/7.png')} />
                                    </Slide>
                                    <Slide index={7}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/8.png')} />
                                    </Slide>
                                    <Slide index={8}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/9.png')} />
                                    </Slide>
                                    <Slide index={9}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/10.png')} />
                                    </Slide>
                                    <Slide index={10}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/11.png')} />
                                    </Slide>
                                </Slider>
                                <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                                <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                            </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Passport)