import { combineReducers } from "redux"
import solutions from './solutions'
import services from './services'
import mainPage from './mainPage'
import adminPage from './adminPage'
import projects from './projects'
import news from './news'
import authReducer from './auth'
import contactsPage from './contactsPage'
import createNews from './createNews'
import changeNews from './changeNews'
import language from './language'
import modal from './Modal'

export default combineReducers({
    solutions: solutions,
    services: services,
    mainPage: mainPage,
    projects: projects,
    news: news,
    auth: authReducer,
    contactsPage: contactsPage,
    createNews: createNews,
    changeNews: changeNews,
    language: language,
    adminPage: adminPage,
    modal: modal,
})
