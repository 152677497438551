import {MAIN_SET_STATE} from '../actions/actionTypes'

const initialState = {
    isMainPage: false,
}

export default function mainPageReducer(state = initialState, action) {
    switch (action.type) {
        case MAIN_SET_STATE:
            return {
                ...state,
                isMainPage: action.isMain
            }
        default: 
        return state
    }
}