import {FETCH_SERVICES_START, FETCH_SERVICES_SUCCESS, FETCH_SERVICESUKR_SUCCESS, FETCH_SERVICES_ERROR} from '../actions/actionTypes'
import firebase from '../../axios/firebase'


export default function fetchServices() {
    return async dispatch => {
        dispatch(fetchServicesStart())
        try {
            firebase.database().ref('services').once('value').then(function(snapshot){
                let services = snapshot.val()
                dispatch(fetchServicesSuccess(services))
            });
            firebase.database().ref('servicesUkr').once('value').then(function(snapshot){
                let services = snapshot.val()
                dispatch(fetchServicesUkrSuccess(services))
            });
        } catch (e) {
            dispatch(fetchServicesError(e))
        }
    }
}

export function fetchServicesStart () {
    return {
        type: FETCH_SERVICES_START
    }
}

export function fetchServicesSuccess (services) {
    return {
        type: FETCH_SERVICES_SUCCESS,
        services
    }
}

export function fetchServicesUkrSuccess (services) {
    return {
        type: FETCH_SERVICESUKR_SUCCESS,
        services
    }
}

export function fetchServicesError (e) {
    return {
        type: FETCH_SERVICES_ERROR,
        error: e
    }
}