import React, {Component} from 'react'
import classes from './NewsMain.module.scss'
import Loader from '../../components/UI/Loader/Loader'
import  fetchNews  from '../../redux/actions/news'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import Fade from 'react-reveal/Fade';

class NewsMain extends Component {

    componentDidMount () {
        this.props.fetchNews()
    }

    renderTitleNews (props) {
        return Object.entries(props).map((item, index) => {
            if (item[1].isTitle === true) {
                function textMin (text) {
                    let str = text.slice(0,400)
                    let a = str.split(' ')
                    a.splice(a.length-1,1)
                    str = a.join(' ')
                    return str+' ...'
                }
                return(
                    <div key={index} className={classes.titleCard}>
                        <img className={classes.titleImg} src={item[1].titleImgUrl} alt='title'/>
                        <div className={classes.cardInfo}>
                            <p className={classes.date}>{item[1].date}</p>
                            <p className={classes.title}>{this.props.language === 'RU' ? item[1].title : item[1].Ukr.title}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? textMin(item[1].text[0]) : textMin(item[1].Ukr.text[0])}</p>
                            <NavLink
                                to={'/news/page' + item[0]}
                                exact={false}
                                className={classes.newsLink}
                                >
                                   {this.props.language === 'RU' ? 'Читать' : 'Читати'}
                            </NavLink>
                        </div>
                    </div>
                )
            }
        })
    }

    renderNews(props) {
        return Object.entries(props).map((item, index) => {
            console.log(item)
            if (item[1].isTitle === false) {
                return (
                    <Fade key={index}>
                    <div className={classes.card}>
                        <img className={classes.img} src={item[1].shortImgUrl} alt='card' />
                        <p className={classes.date}>{item[1].date}</p>
                        <NavLink
                            to={'/news/page' + item[0]}
                            exact={false}
                            className={classes.newsLink}
                            >
                                {this.props.language === 'RU' ? item[1].title : item[1].Ukr.title}
                        </NavLink>
                    </div>
                    </Fade>
                )
            }
        })
    }

    getNews () {
        return (
            <>
            <h2 className={classes.mainTitle}>{this.props.language === 'RU' ? 'Новости' : 'Новини'}</h2>
            {this.renderTitleNews(this.props.news.news)}
            <div className={classes.row}>
                {this.renderNews(this.props.news.news)}
            </div>
            </>
        )
    }

    render () {
        return (
            <div className={classes.NewsMain}>
                <div className='wrapper'>
                    {
                        this.props.news.loading
                        ?
                        <Loader />
                        :
                        this.getNews()
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        news: state.news,
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNews: () => dispatch(fetchNews()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsMain)