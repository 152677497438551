import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import classes from './Footer.module.scss'
import logo from '../../img/logo_header.png'
import {connect} from 'react-redux'
import  fetchSolutions  from '../../redux/actions/solutions'
import  fetchServices  from '../../redux/actions/services'

class Footer extends Component {

    componentDidMount () {
        this.props.fetchSolutions()
        this.props.fetchServices()
    }

    renderServicesLinks(items) {
        return items.map((service, index) => {
            return (
                    <NavLink
                    key={index}
                    to={'/services/page' + index}
                    exact={false}
                    className={classes.navLink}
                    >
                        {service.name}
                    </NavLink>
            )
        })
    }
    
    renderSolutionsLinks(items) {
        return items.map((solution, index) => {
            return (
                    <NavLink
                    key={index}
                    to={'/solutions/page' + index}
                    exact={false}
                    className={classes.navLink}
                    >
                        {solution.name}
                    </NavLink>
            )
        })
    }

    render () {
        return (
            <footer className={this.props.isAdminPage ? classes.Footer + ' ' + classes.fullpage : classes.Footer}>
                <div className='wrapper'>
                    <div className={classes.content}>
                        <div className={classes.logoC}>
                            <NavLink
                            to={'/'}
                            exact={false}
                            >
                                <img className={classes.logo} src={logo} alt='footer_logo'/>
                            </NavLink>
                            <div className={classes.logoLinks}>
                                <div className={classes.logoLink}>
                                    <a rel="noopener noreferrer" target='_blank' href='http://facebook.com/esave.com.ua'>
                                    <img src={require('../../img/sertif/facebook.png')} alt='facebook'/> facebook
                                    </a>
                                </div>
                                <div className={classes.logoLink}>
                                <NavLink
                                to={'/news'}
                                exact={false}
                                >
                                    <img src={require('../../img/sertif/news.png')} alt='news'/> {this.props.language === 'RU' ? 'Новости' : 'Новини'}
                                </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className={classes.links}>
                            <NavLink
                                exact={false}
                                to='/sertificat'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'Энергосертификат' : 'Енергосертифікат'}
                            </NavLink>
                            <NavLink
                                exact={false}
                                to='/energoeffect'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'Раздел "Энергоэффективность"' : 'Розділ "Енергоефективність"'}
                            </NavLink>
                            <NavLink
                                exact={false}
                                to='/passport'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'Энергопаспорт' : 'Енергопаспорт'}
                            </NavLink>
                            <NavLink
                                exact={false}
                                to='/audit'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'Энергоаудит' : 'Енергоаудит'}
                            </NavLink>
                        </div>
                        <div className={classes.links}>
                            <NavLink
                                exact={false}
                                to='/osbb'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'ОСМД' : 'ОСББ'}
                            </NavLink>
                            <NavLink
                                exact={false}
                                to='/otg'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'ОТГ' : 'ОТГ'}
                            </NavLink>
                            <NavLink
                                exact={false}
                                to='/developer'
                                className={classes.navLink}
                                >
                                {this.props.language === 'RU' ? 'Застройщикам' : 'Забудовникам'}
                            </NavLink>
                        </div>
                        <div className={classes.socials}>
                            <a href='tel:+380678080082' className='phone1'>
                                <img src={require('../../img/sertif/phone.png')} alt='phone'/>+38 067 808 00 82
                            </a>
                            <a href='tel:+380938080082' className='phone2'>
                                <img src={require('../../img/sertif/phone.png')} alt='phone'/>+38 093 808 00 82
                            </a>
                            <a href='mailto:office@esave.com.ua' className={classes.email} style={{textTransform: 'lowercase'}}>
                                <img src={require('../../img/sertif/email.png')} alt='email'/>office@esave.com.ua
                            </a>
                            <p className={classes.adress} style={{textTransform: 'none'}}>
                                <img src={require('../../img/sertif/adress.png')} alt='email'/>
                            {this.props.language === 'RU' ? 
                            '03164, г. Киев, ул. Олевская 5' : 
                            '03164, м. Київ, вул. Олевська 5'
                            }</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

function mapStateToProps(state) {
    return {
        isAdminPage: state.adminPage.isAdminPage,
        language: state.language.language,
        solutions: state.solutions,
        services: state.services,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSolutions: () => dispatch(fetchSolutions()),
        fetchServices: () => dispatch(fetchServices()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)