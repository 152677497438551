import {FETCH_SOLUTIONS_START, FETCH_SOLUTIONSUKR_SUCCESS, FETCH_SOLUTIONS_SUCCESS, FETCH_SOLUTIONS_ERROR} from '../actions/actionTypes'

const initialState = {
    solutions: [],
    solutionsUkr: [],
    error: null,
    menu: false,
    loading: true
}

export default function solutionsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SOLUTIONS_START: 
        return {
            ...state,
            loading: true
        }
        case FETCH_SOLUTIONS_SUCCESS: 
        return {
            ...state, 
            solutions: action.solutions,
            loading: false
        }
        case FETCH_SOLUTIONSUKR_SUCCESS: 
        return {
            ...state, 
            solutionsUkr: action.solutions,
            loading: false
        }
        case FETCH_SOLUTIONS_ERROR: 
        return {
            ...state,
            error: action.error,
            loading: true
        }
        default: 
        return state
    }
}