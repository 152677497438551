import React, {Component} from 'react'
import classes from '../../Service/Sertificat/Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class Developer extends Component {
    openModal() {
        this.props.setModal(true)
    }
    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'ДЛЯ ЗАСТРОЙЩИКОВ' : 'ДЛЯ ЗАБУДОВНИКІВ'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'УСЛУГИ E-SAVE ДЛЯ ЗАСТРОЙЩИКОВ И ПРОЕКТИРОВЩИКОВ:' : 'ПОСЛУГИ E - SAVE ДЛЯ ЗАБУДОВНИКІВ ТА ПРОЕКТУВАЛЬНИКІВ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon1.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ЭНЕРГОСЕРТИФИКАТ' : 'Енергосертифікат'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon7.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'РАЗДЕЛ «ЭНЕРГОЭФФЕКТИВНОСТЬ»' : 'Розділ "Енергоефективність"'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon6.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ЭНЕРГОПАСПОРТ' : 'ЕНЕРГОПАСПОРТ'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.about}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide} style={{textAlign: 'left'}}>{this.props.language === 'RU' ? 'ВАЖНО ЗНАТЬ, ЧТО' : 'ВАЖЛИВО ЗНАТИ, ЩО'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'У нас Вы можете получить консультацию о возможностях сотрудничества, а также о необходимой документации для подготовки проектов, прохождения экспертизы или сдачи объектов в эксплуатацию.' : `У нас Ви можете отримати консультацію про можливості співпраці, а також про необхідну документацію для підготовки проектів, проходження експертизи або здачі об'єктів в експлуатацію .`}</p>
                            <p className={classes.titleSide} style={{textAlign: 'left', marginTop: 10 + 'px'}}>{this.props.language === 'RU' ? 'РАЗДЕЛ "ЭНЕРГОЭФФЕКТИВНОСТЬ" В СОСТАВЕ ПРОЕКТА' : 'РОЗДІЛ "ЕНЕРГОЕФЕКТИВНІСТЬ" У СКЛАДІ ПРОЕКТУ'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Согласно установленного стандарта ДСТУ Б А.2.2-8:2010, при проектировании жилых и общественных зданий, требуется от заказчика выполнения раздела "Энергоэффективность". Он является составной проектной документации, где отражаются и обобщаются решения проекта по реализации требований по энергосбережению и энергетической эффективности зданий.' : `Згідно встановленого стандарту ДСТУ Б А.2.2-8: 2010 при проектуванні житлових і громадських будівель, потрібно від замовника виконання розділу "Енергоефективність". Він є складовою проектної документації , де відображаються і узагальнюються рішення проекту по реалізації вимог з енергозбереження та енергетичної ефективності будівель.`}</p>
                        </div>
                        <div className={classes.rs}>
                        <img src={require('../../../img/sertif/banner17.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed8}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner18.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ПОЧЕМУ СТОИТ ВЫБРАТЬ НАС:' : `ЧОМУ ВАРТО ВИБРАТИ НАС:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'опыт разработки раздела «Энергоэффективность» в составе проекта;' : `досвід розробки розділу «Енергоефективність» у складі проекту;`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'аттестованные эксперты по сертификации зданий;' : `атестовані експерти з сертифікації будівель;`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'аналитики в сфере энергоэффективного строительства;' : `аналітики в сфері енергоефективного будівництва;`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'выполнение в кратчайшие сроки;' : `аналітики в сфері енергоефективного будівництва;`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'знание и соблюдение всех ДСТУ, ДБН;' : `знання і дотримання всіх ДСТУ, ДБН;`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'современная методика обработки данных;' : `знання і дотримання всіх ДСТУ, ДБН;`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'гарантировано успешное прохождение экспертизы по Разделу «Энергоэффективность»;' : `гарантовано успішне проходження експертизи до Розділу «Енергоефективність»;`}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Заказать решения для застройщиков' : 'Замовити рiшення для забудовникiв'}
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'НАМ ДОВЕРЯЮТ:' : 'НАМ ДОВІРЯЮТЬ:'}</h2>
                        <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={8}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 4}
                            step={1}
                            infinite={true}
                            >
                                <Slider>
                                    <Slide index={0}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/1.png')} />
                                    </Slide>
                                    <Slide index={1}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/2.png')} />
                                    </Slide>
                                    <Slide index={2}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/3.png')} />
                                    </Slide>
                                    <Slide index={3}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/4.png')} />
                                    </Slide>
                                    <Slide index={4}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/5.png')} />
                                    </Slide>
                                    <Slide index={5}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/6.png')} />
                                    </Slide>
                                    <Slide index={6}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/7.png')} />
                                    </Slide>
                                    <Slide index={7}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/8.png')} />
                                    </Slide>
                                </Slider>
                                <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                                <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                            </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Developer)