import React from 'react'
import classes from './AboutMain.module.scss'
import news from '../../img/news.png'
import {NavLink} from 'react-router-dom'

const AboutMain = (props) => (
    <div className={classes.AboutMain} id='about'>
        <div className='wrapper'>
            <div className={classes.content}>
                <div className={classes.leftside}>
                    <p className={classes.title}>{props.language === 'RU' ? 'о нас' : 'про нас'}</p>
                    <p className={classes.text}>{props.language === 'RU' ? 'Команда E-SAVE – это опытные энергоаудиторы, аккредитованные эксперты по энергосертификации зданий и обследованию инженерных систем, энергоменеджеры и аналитики в области энергоэффективного строительства.' : 'Команда E – SAVE – це досвідчені енергоаудитори, акредитовані експерти по енергосертифікаціі будівель і обстеження інженерних систем, енергоменеджери та аналітики в області енергоефективного будівництва.'}</p>
                    <p className={classes.text}>{props.language === 'RU' ? 'Наша корпоративная культура – это постоянный процесс обучения новым технологиям и анализ законодательной базы в сфере энергоэффективности и строительства.' : 'Наша корпоративна культура - це постійний процес навчання новим технологіям і аналіз законодавчої бази в сфері енергоефективності та будівництва.'}</p>
                    <p className={classes.title}>{props.language === 'RU' ? 'Услуги E-SAVE' : 'ПОСЛУГИ E-SAVE'}</p>
                    <div className={classes.links}>
                        <NavLink
                            exact={false}
                            to='/sertificat'
                            className={classes.navLink}
                            >
                            {props.language === 'RU' ? 'Энергосертификат' : 'Енергосертифікат'}
                        </NavLink>
                        <NavLink
                            exact={false}
                            to='/energoeffect'
                            className={classes.navLink}
                            >
                            {props.language === 'RU' ? 'Раздел "Энергоэффективность"' : 'Розділ "Енергоефективність"'}
                        </NavLink>
                        <NavLink
                            exact={false}
                            to='/passport'
                            className={classes.navLink}
                            >
                            {props.language === 'RU' ? 'Энергопаспорт' : 'Енергопаспорт'}
                        </NavLink>
                        <NavLink
                            exact={false}
                            to='/audit'
                            className={classes.navLink}
                            >
                            {props.language === 'RU' ? 'Энергоаудит' : 'Енергоаудит'}
                        </NavLink>
                    </div>
                </div>
                <div className={classes.rightside}>
                    <img 
                    className={classes.newsImg}
                    src={news}
                    alt={news}
                    ></img>
                </div>
            </div>
            <div className={classes.icons}>
                <div className={classes.icon}>
                    <img src={require('../../img/1.png')} alt='icon'/>
                    <p className={classes.text}>{props.language === 'RU' ? 'ВЫПОЛНЕННЫХ ЭНЕРГОАУДИТОВ' : 'ВИКОНАНИХ ЕНЕРГОАУДИТIВ'}</p>
                </div>
                <div className={classes.icon}>
                    <img src={require('../../img/2.png')} alt='icon'/>
                    <p className={classes.text}>{props.language === 'RU' ? 'ГАРАНТИРОВАННОЕ СНИЖЕНИЕ ПОТРЕБЛЕНИЯ ЭНЕРГИИ ПОСЛЕ МОДЕРНИЗАЦИИ' : 'ГАРАНТОВАНЕ ЗНИЖЕННЯ СПОЖИВАННЯ ЕНЕРГІЇ ПІСЛЯ МОДЕРНІЗАЦІЇ'}</p>
                </div>
                <div className={classes.icon}>
                    <img src={require('../../img/3.png')} alt='icon'/>
                    <p className={classes.text}>{props.language === 'RU' ? 'УСПЕШНО ПРОЙДЕННЫХ ЭКСПЕРТИЗ РАЗДЕЛОВ «ЭНЕРГОЭФФЕКТИВНОСТЬ»' : 'УСПІШНО ПРОЙДЕНИХ ЕКСПЕРТИЗ РОЗДІЛІВ «Енергоефективність»'}</p>
                </div>
                <div className={classes.icon}>
                    <img src={require('../../img/4.png')} alt='icon'/>
                    <p className={classes.text}>{props.language === 'RU' ? 'РАЗРАБОТАННЫХ ЭНЕРГОСЕРТИФИКАТОВ СООТВЕТСТВУЮЩИХ ТРЕБОВАНИЯМ ЗАКОНОДАТЕЛЬСТВА' : 'РОЗРОБЛЕНИХ ЕНЕРГОСЕРТИФІКАТIВ, ЩО ВІДПОВІДАЮТЬ ВИМОГАМ ЗАКОНОДАВСТВА'}</p>
                </div>
            </div>
            <div
            onClick={props.openModal}
            className={classes.link}
            >
                    {props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
            </div>
        </div>
    </div>
)

export default AboutMain