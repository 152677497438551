import React, {Component} from 'react'
import classes from './Projects.module.scss'
import Loader from '../../components/UI/Loader/Loader'
import  fetchProjects  from '../../redux/actions/projects'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import Fade from 'react-reveal/Fade';

class Projects extends Component {

    componentDidMount () {
        this.props.fetchProjects()
    }

    renderProjects(props) {
        return props.projects.projects.map((project, index) => {
            return (
                <Fade  key={index}>
                <div className={classes.projectCard}>
                    <img className={classes.projectImg} src={project.mediumImgUrl} alt='cardImg'/>
                    <p className={classes.projectData}>{project.date}</p>
                    <NavLink
                        key={index + 10}
                        to={'/projects/page' + index}
                        exact={false}
                        className={classes.projectName}
                        >
                        <p className={classes.projectName}>{project.name}</p>
                    </NavLink>
                    <NavLink
                        key={index}
                        to={'/projects/page' + index}
                        exact={false}
                        className={classes.navLink}
                        >
                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                <path d="M28.9806 6.25258C35.19 12.462 35.19 22.538 28.9806 28.7474C22.7712 34.9569 12.6952 34.9569 6.48577 28.7474C0.276334 22.538 0.276334 12.462 6.48577 6.25258C12.6952 0.0431425 22.7712 0.0431428 28.9806 6.25258Z" fill="white" stroke="#1A1A1A" strokeMiterlimit="10"/>
                                <path d="M12.0312 22.9688L22.9688 12.0312" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.3125 12.0312H22.9688V19.6875" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="35" height="35" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                    </NavLink>
                </div>
                </Fade>
            )
        })
    }

    render () {
        return (
            <div className={classes.Projects}>
                <div className='wrapper'>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'наши проекты' : 'Наші проекти'}</h1>
                    <div className={classes.content}>
                        {
                            this.props.projects.loading
                            ?
                            <Loader />
                            :
                                this.renderProjects(this.props)
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects,
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchProjects: () => dispatch(fetchProjects()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)