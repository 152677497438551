import React, {Component} from 'react'
import classes from './AdminPanel.module.scss'
import {connect} from 'react-redux'
import  fetchNews  from '../../redux/actions/news'
import  delNews  from '../../redux/actions/delNews'
import Loader from '../../components/UI/Loader/Loader'
import {NavLink} from 'react-router-dom'


class AdminPanel extends Component {

    componentDidMount () {
        this.props.fetchNews()
    }



    del(item, index, name) {
       let conf = window.confirm('Вы действительно хотите удалить новость ' + name + '?')
       if (conf === true) {
        this.props.delNews(item, index)
        setTimeout(() => { this.props.fetchNews() }, 1000)
       }
    }

    getNews (props) {
        return Object.entries(props).map((item, index) => {

            return (
                <div className={classes.news} key={index}>
                    <p className={classes.newsTitle}>{item[1].title}</p>
                    <NavLink
                        to={`/adminPanel/changeNews${item[0]}`}
                        exact={false}
                        className={classes.change}
                        >
                        Изменить новость
                    </NavLink>
                    <p className={classes.del} onClick={() => this.del(item[0], index, item[1].title)}>Удалить новость</p>
                </div>
            )
        })
    }

    render () {
        return (
            <div className={classes.AdminPanel}>
                <div className='wrapper'>
                    <div className={classes.News}>
                        <NavLink
                            to={'/adminPanel/createNews'}
                            exact={false}
                            className={classes.addNews}
                            >
                                Создать новость
                        </NavLink>
                        <h1 className={classes.title}>Список новостей:</h1>
                        {
                            this.props.news.loading
                            ?
                            <Loader />
                            :
                            this.getNews(this.props.news.news)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        news: state.news
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNews: () => dispatch(fetchNews()),
        delNews: (item, index) => dispatch(delNews(item, index))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)