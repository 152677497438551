import React, {Component} from 'react'
import classes from '../../Service/Sertificat/Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class OTG extends Component {
    openModal() {
        this.props.setModal(true)
    }
    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'ДЛЯ ОТГ' : 'ДЛЯ ОТГ'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'УСЛУГИ E-SAVE ДЛЯ ОТГ:' : 'ПОСЛУГИ E-SAVE ДЛЯ ОТГ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon16.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ЭНЕРГОАУДИТ' : 'Енергоаудит'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon1.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ЭНЕРГОСЕРТИФИКАТ' : 'Енергосертифікат'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon6.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ПРОЕКТИРОВАНИЕ' : 'Проектування'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.about}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ВАЖНО ЗНАТЬ, ЧТО' : 'ВАЖЛИВО ЗНАТИ, ЩО'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Децентрализация дала возможность превратить перспективные планы общин в реальность. E-SAVE активно сотрудничает с ОТГ по всей Украине, выполняя комплексные энергоаудиты муниципальных зданий перед проведением термомодернизации. Команда E-SAVE проводит сертификацию энергоэффективности муниципальных зданий, разрабатывает энергопаспорта и осуществляет проектирование технический решений термомодернизации зданий.' : 'Децентралізація дала можливість перетворити перспективні плани громад в реальність. E-SAVE активно співпрацює з ОТГ по всій Україні, виконуючи комплексні енергоаудити муніципальних будівель перед проведенням термомодернізації. Команда E-SAVE проводить сертифікацію енергоефективності муніципальних будівель, розробляє енергопаспорта і здійснює проектування технічний рішень термомодернізації будівель .'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Финансирование термомодернизации требует качественной подготовки и ТЕО проектов. Благодаря работе нашей команды, Вы получите четкий план действий, что минимизирует форс-мажоры в процессе реконструкции или строительства.' : `Фінансування термомодернізації вимагає якісної підготовки і ТЕО проектів. Завдяки роботі нашої команди, Ви отримаєте чіткий план дій, що мінімізує форс-мажори в процесі реконструкції або будівництва.`}</p>
                        </div>
                        <div className={classes.rs}>
                        <img src={require('../../../img/sertif/banner13.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed6}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner14.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ЧТО ДАЕТ НАЛИЧИЕ ЭНЕРГОАУДИТА И ЭНЕРГЕТИЧЕСКОГО СЕРТИФИКАТА ЗДАНИЯ:' : `ЩО ДАЄ НАЯВНІСТЬ ЕНЕРГОАУДИТУ та ЕНЕРГЕТИЧНОГО СЕРТИФІКАТУ БУДІВЛІ:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Достоверные данные, полученные высокоточным измерительным оборудованием и современным программным комплексом.' : `Достовірні дані, отримані високоточним вимірювальним обладнанням і сучасним програмним комплексом.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Четкий план действий и мероприятий для термомодернизации для конкретного здания.' : `Чіткий план дій і заходів стосовно термомодернізації для конкретного будинку.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Мероприятия по термомодернизации с гарантированной экономией до 70% тепла.' : `Заходи по термомодернізації з гарантованою економією до 70% тепла.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Обоснование конкретных решений по модернизации для руководства ОТГ или Международных организаций, оказывающих финансовую помощь.' : `Обгрунтування конкретних рішень по модернізації для керівництва ОТГ або Міжнародних організацій, що надають фінансову допомогу .`}</p>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed3 + ' ' + classes.whoNeed4} style={{marginTop: 30 + 'px'}}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide} style={{textAlign: 'right'}}>{this.props.language === 'RU' ? 'ПОЧЕМУ СТОИТ ВЫБРАТЬ НАС:' : `ЧОМУ ВАРТО ВИБРАТИ НАС:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Более 1000 выполненных энергоаудитов.' : `Більше 1000 виконаних енергоаудитів.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Аттестованные эксперты по сертификации зданий.' : `Атестовані експерти з сертифікації будівель.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Аналитики в сфере энергоэффективного строительства.' : `Аналітики в сфері енергоефективного будівництва.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Высокоточное Европейское измерительное оборудование и современная методика обработки данных.' : `Високоточне Європейське вимірювальне обладнання і сучасна методика обробки даних.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Опыт сопровождения проектов термомодернизации зданий.' : `Досвід супроводу проектів термомодернізації будівель.`}</p>
                        </div>
                        <div className={classes.rs}>
                            <img src={require('../../../img/sertif/banner15.jpg')} alt='banner' style={{marginTop: 30 + 'px'}}/>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed7}  style={{marginTop: 30 + 'px'}}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner16.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'КАК СДЕЛАТЬ ЗДАНИЕ СОВРЕМЕННЫМ И ЭНЕРГОЭФФЕКТИВНЫМ?' : `ЯК ЗРОБИТИ БУДІВЛЮ СУЧАСНУ І ЕНЕРГОЕФЕКТИВНУ?`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Понять, что первый шаг – профессиональный энергоаудит.' : `Зрозуміти, що перший крок - професійний енергоаудит.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'С помощью энергоаудита получить четкие технические решения, которые проложат пошаговый путь от точки «А» к точке «Б», где каждый шаг будет иметь свою эффективность и будет точно просчитан.' : `За допомогою енергоаудиту отримати чіткі технічні рішення , які прокладуть покроковий шлях від точки «А» до точки «Б», де кожен крок буде мати свою ефективність і буде точно прорахований.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Наличие плана поможет эффективно потратить средства на модернизацию.' : `Наявність плану допоможе ефективно витратити кошти на модернізацію .`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Осознать, что иностранные доноры готовы выделять финансовую помощь тем ОТГ, где есть ответственная власть, четкий план и видение результата.' : `Усвідомити, що іноземні донори готові виділяти фінансову допомогу тим ОТГ, де є відповідальна влада, чіткий план і бачення результату.`}</p>
                            <p className={classes.textBold}>{this.props.language === 'RU' ? 'Команда ESAVE готова предоставить квалифицированные услуги по всей Украине!' : 'Команда ESAVE готова надати кваліфіковані послуги по всій Україні!'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Заказать решение для ОТГ' : 'Замовити рiшення для ОТГ'}
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'НАМ ДОВЕРЯЮТ:' : 'НАМ ДОВІРЯЮТЬ:'}</h2>
                        <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={5}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 3}
                            step={1}
                            infinite={true}
                            >
                                <Slider>
                                    <Slide index={0}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/4.png')} />
                                    </Slide>
                                    <Slide index={1}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/1.png')} />
                                    </Slide>
                                    <Slide index={2}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/2.png')} />
                                    </Slide>
                                    <Slide index={3}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/3.png')} />
                                    </Slide>
                                    <Slide index={4}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/5.png')} />
                                    </Slide>
                                </Slider>
                                <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                                <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                            </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OTG)