import React, {Component} from 'react'
import classes from './Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class Sertificat extends Component {
    openModal() {
        this.props.setModal(true)
    }

    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'Энергосертификат' : 'ЕНЕРГОСЕРТИФІКАТ'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'закажите его у нас и вы гарантировано получите' : 'ЗАМОВТЕ ЙОГО У НАС І ВИ ГАРАНТОВАНО ОТРИМАЄТЕ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon1.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Соответствие всем требованиям законодательства' : 'Відповідність всім вимогам законодавства'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon2.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Выполнение от 2-х дней' : 'Виконання від 2-х днів'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon3.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Достоверные расчеты от лучших энергоаудиторов Украины' : 'Достовірні розрахунки від кращих енергоаудиторів україни'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.about}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'что такое энергосертификат' : 'ЩО ТАКЕ ЕНЕРГОСЕРТИФІКАТ?'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'В 2017 году был принят Закон Украины "Про энергетическую эффективность зданий", направленный на улучшение энергоэффективности в строительстве и снижению потребления энергии в существующих зданиях.' : 'У 2017 році був прийнятий Закон України «Про енергетичну ефективність будівель», спрямований на поліпшення енергоефективності в будівництві і зниження споживання енергії в існуючих будівлях.'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Энергосертификат - это электронный документ установленной формы, в котором указываются показатели и класс энергетической эффективности здания, фактические и проектные характеристики конструкций и инженерных систем.' : 'Енергосертифікат - це електронний документ чинної форми, в якому зазначаються показники і клас енергетичної ефективності будівлі, фактичні і проектні характеристики конструкцій та інженерних систем.'}</p>
                        </div>
                        <div className={classes.rs + ' '+ classes.lessSide}>
                        <img src={require('../../../img/sertif/banner2.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.whoNeed}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'для кого обязательно' : `ДЛЯ КОГО ОБОВ'ЯЗКОВО?`}</p>
                            <p className={classes.textBold}>{this.props.language === 'RU' ? 'С 1 ИЮЛЯ 2019 ГОДА СЕРТИФИКАЦИЯ ЭНЕРГОЭФФЕКТИВНОСТИ ЗДАНИЙ ОБЯЗАТЕЛЬНА ДЛЯ:' : `З 1 ЛИПНЯ 2019 РОКУ СЕРТИФІКАЦІЯ ЕНЕРГОЕФЕКТИВНОСТІ БУДІВЕЛЬ ОБОВ'ЯЗКОВА ДЛЯ:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'объектам строительства (нового строительства, реконструкции, капитального ремонта) что по классу последствий (ответственности) относятся к объектам со средними (СС2) и значительными (СС3) последствиями, которые определяются в соответствии с Законом Украины "О регулировании градостроительной деятельности"' : `об'єктів будівництва (нового будівництва, реконструкції, капітального ремонту), що за класом наслідків (відповідальності) відносяться до об'єктів з середніми (СС 2 ) і значними (СС3) наслідками, які визначаються відповідно до Закону України "Про регулювання містобудівної діяльності"`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'зданиям государственной собственности с отапливамой площадью более 250 квадратных метров, которые часто посещают граждане и во всех помещениях в которых расположены органы государственной власти' : 'будівель державної власності з опалювальною площею понад 250 квадратних метрів, які часто відвідують громадяни і у всіх приміщеннях яких розташовані органи державної влади'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'зданиям с отапливаемой площадью более 250 квадратных метров, во всех помещениях, в которых расположены органы местного самоуправления (в случае осуществления иим термомодернизации таких здания)' : 'будівель з опалювальною площею понад 250 квадратних метрів, у всіх приміщеннях яких розташовані органи місцевого самоврядування (у разі здійснення ними термомодернізації таких будівель)'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'зданиях, в которых осуществляется термомодернизация, для которой предоставляется государственная поддержка, и которая влечет достижения класса энергетической эффективности здания не ниже минимальных требований к энергетической эффективности здания' : 'будівель, в яких здійснюється термомодернізація, для якої надається державна підтримка і яка тягне досягнення класу енергетичної ефективності будівлі не нижче мінімальних вимог до енергетичної ефективності будівлі;'}</p>
                            <p className={classes.textBold}>{this.props.language === 'RU' ? 'А также для ОСМД, которые планируют участвовать в программе Фонда Энергоэффективности по возмещению расходов на термомодернизацию жилого дома.' : 'А також для ОСББ, які планують брати участь у програмі Фонду енергоефективності по відшкодуванню витрат на термомодернізацію житлового будинку.'}</p>
                        </div>
                    </div>
                    <div className={classes.steps}>
                        <h2 className={classes.titleSide}>{this.props.language === 'RU' ? 'Этапы сертификации' : 'ЕТАПИ СЕРТИФІКАЦІЇ'}</h2>
                        <div className={classes.ls}>
                            <div className={classes.col}>
                                <img src={require('../../../img/sertif/icon4.png')} alt='icon'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'сбор данных по фактически измеренным или проектным показателям' : 'Збір даних по фактично вимірюваних або проектних показниках'}</p>
                            </div>
                            <div className={classes.col}>
                                <img src={require('../../../img/sertif/icon5.png')} alt='icon'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'расчет энергетических показателей' : 'Розрахунок енергетичних показників'}</p>
                            </div>
                            <div className={classes.col}>
                                <img src={require('../../../img/sertif/icon6.png')} alt='icon'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'составление ркомендация по повышению энергоэффективности' : 'Складання рекомендацій щодо підвищення енергоефективності '} <br/><span>{this.props.language === 'RU' ? '(для существующих зданий)' : '(для існуючих будинків)'}</span></p>
                            </div>
                            <div className={classes.col}>
                                <img src={require('../../../img/sertif/icon8.png')} alt='icon'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'прохождение проверки сертификата в гаээ и внесение его в электронную базу данных' : 'ЕНЕРГОСЕРТИФІКАТ'}</p>
                            </div>
                            <div className={classes.col}>
                                <img src={require('../../../img/sertif/icon7.png')} alt='icon'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'заполнение бланка энергосертификата и подготовка пакета документов для прохождения проверки в ГАЭЭ' : 'Заповнення бланку енергосертифікату і підготовка пакету документів для проходження перевірки у ДАЕЕ'}</p>
                            </div>
                        </div>
                        <div className={classes.rs}>
                        <img src={require('../../../img/sertif/banner3.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'Мы уже провели энергосертификацию зданий для:' : 'МИ ВЖЕ ПРОВЕЛИ СЕРТИФІКАЦІЮ БУДИНКІВ ДЛЯ:'}</h2>
                        <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={8}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 4}
                            step={1}
                            infinite={true}
                            >
                            <Slider>
                                <Slide index={0}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/1.png')} />
                                </Slide>
                                <Slide index={1}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/2.png')} />
                                </Slide>
                                <Slide index={2}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/3.png')} />
                                </Slide>
                                <Slide index={3}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/4.png')} />
                                </Slide>
                                <Slide index={4}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/5.png')} />
                                </Slide>
                                <Slide index={5}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/6.png')} />
                                </Slide>
                                <Slide index={6}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/7.png')} />
                                </Slide>
                                <Slide index={7}>
                                    <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/8.png')} />
                                </Slide>
                            </Slider>
                            <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                            <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                        </CarouselProvider>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Заказать энергосертификат' : 'Замовити енергосертифікат'}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Sertificat)