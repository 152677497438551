import axios from '../../axios/axios'
import firebase from '../../axios/firebase'

export default function delNews (item, index) {
    return async (dispatch, getState) => {
        const token = getState().auth.token
        console.log(item, index)
        axios.delete(`/news/${item}.json`, {
            params: {auth: token}
        })
        firebase.storage().ref('news/big-img-'+ item + '-0.png').delete();
        firebase.storage().ref('news/big-img-'+ item + '-1.png').delete();
        firebase.storage().ref('news/medium-img-'+ item + '.png').delete();
        firebase.storage().ref('news/short-img-'+ item + '.png').delete();
        firebase.storage().ref('news/title-img-'+ item + '.png').delete();
    }
}
