export const FETCH_SOLUTIONS_START = 'FETCH_SOLUTIONS_START'
export const FETCH_SOLUTIONS_SUCCESS = 'FETCH_SOLUTIONS_SUCCESS'
export const FETCH_SOLUTIONSUKR_SUCCESS = 'FETCH_SOLUTIONSUKR_SUCCESS'
export const FETCH_SOLUTIONS_ERROR = 'FETCH_SOLUTIONS_ERROR'

export const FETCH_SERVICES_START = 'FETCH_SERVICES_START'
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICESUKR_SUCCESS = 'FETCH_SERVICESUKR_SUCCESS'
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR'

export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR'

export const FETCH_NEWS_START = 'FETCH_NEWS_START'
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
export const FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR'

export const SHOW_MODAL = 'SHOW_MODAL'

export const MAIN_SET_STATE = 'MAIN_SET_STATE'
export const ADMIN_SET_STATE = 'ADMIN_SET_STATE'
export const CONTACTS_SET_STATE = 'CONTACTS_SET_STATE'
export const CHANGE_PAGE = 'CHANGE_PAGE'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const CREATE_NEWS = 'CREATE_NEWS'
export const DEL_NEWS = 'DEL_NEWS'
export const CHANGE_NEWS = 'CHANGE_NEWS'
export const CHANGE_IMG = 'CHANGE_IMG'

export const RESET_NEWS_CHANGING = 'RESET_NEWS_CHANGING'

export const RESET_NEWS_CREATION = 'RESET_NEWS_CREATION'
export const UPLOAD_IMG = 'UPLOAD_IMG'

export const SET_LANGUAGE = 'SET_LANGUAGE'
