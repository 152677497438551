import React, {Component} from 'react'
import classes from '../../Service/Sertificat/Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class OSBB extends Component {
    openModal() {
        this.props.setModal(true)
    }
    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'ДЛЯ ОСМД' : 'ДЛЯ ОСББ'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'УСЛУГИ E-SAVE ДЛЯ ОСМД:' : 'ПОСЛУГИ E-SAVE ДЛЯ ОСББ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon16.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ЭНЕРГОАУДИТ' : 'Енергоаудит'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon1.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ЭНЕРГОСЕРТИФИКАТ' : 'Енергосертифікат'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon6.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ПРОЕКТИРОВАНИЕ' : 'Проектування'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.about}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ВАЖНО ЗНАТЬ, ЧТО' : 'ВАЖЛИВО ЗНАТИ, ЩО'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'E-SAVE активно сотрудничает с ОСББ по вопросам энергоэффективности. У нас Вы можете получить консультацию о программах финансирования проектов по термомодернизации, а также что нужно знать совладельцам жилых домов об энергоаудите и зачем он нужен. Командой сертифицированных специалистов было выполнено более 1000 энергоаудитов.' : 'E - SAVE активно співпрацює з ОСББ з питань енергоеффективності. У нас Ви можете отримати консультацію з програми фінансування проектів по термомодернізації, а також що потрібно знати співвласникам житлових будинків про енергоаудит та навіщо він потрібен. Командою сертифікованих фахівців було виконано більш 1000 енергоаудитів .'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'В данный момент активно начал свою работу Фонд энергоэффективности и благодаря этому, у совладельцев жилых домов появилась возможность проводить энергоаудит и термомодернизацию домов с компенсацией средств до 70%. Основным условием участия в программе Фонда Энергоэффективности является проведение энергоаудита и разработка энергетического сертификата здания.' : `В даний час активно почав свою роботу Фонд енергоефективності і завдяки цьому, у співвласників житлових будинків з'явилася можливість проводити енергоаудит та термомодернізацію будинків з компенсацією коштів до 70%. Основною умовою участі у програмі Фонду енергоефективності є проведення енергоаудиту і розробка енергетичного сертифікату будівлі.`}</p>
                        </div>
                        <div className={classes.rs}>
                        <img src={require('../../../img/sertif/banner9.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed5}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner10.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ЭТАПЫ ЭНЕРГОАУДИТА:' : `ЕТАПИ ЕНЕРГОАУДИТУ:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? ' Сбор исходной информации, визуальное обследование, составление плана инструментального обследования.' : `Збір вихідної інформації, візуальне обстеження, складання плану інструментального обстеження.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? ' Инструментальное обследование, обработка результатов, энергетические расчеты, анализ мероприятий по энергоэффективности и улучшения микроклимата в помещениях.' : `Інструментальне обстеження, обробка результатів, енергетичні розрахунки, аналіз заходів з енергоефективності та поліпшення мікроклімату в приміщеннях.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? ' Экономические расчеты и финансовая оценка мероприятий по энергоэффективности.' : `Економічні розрахунки і фінансова оцінка заходів з енергоефективності.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? ' Подготовка отчетов по энергоаудиту и проведение презентации заказчику.' : `Підготовка звітів з енергоаудиту та проведення презентації замовнику.`}</p>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed3 + ' ' + classes.whoNeed4}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide} style={{textAlign: 'right'}}>{this.props.language === 'RU' ? 'ПОЧЕМУ СТОИТ ВЫБРАТЬ НАС:' : `ЧОМУ ВАРТО ВИБРАТИ НАС:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Более 1000 выполненных энергоаудитов.' : `Більше 1000 виконаних енергоаудитів.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Аттестованные эксперты по сертификации зданий.' : `Атестовані експерти з сертифікації будівель.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Аналитики в сфере энергоэффективного строительства.' : `Аналітики в сфері енергоефективного будівництва.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Высокоточное Европейское измерительное оборудование и современная методика обработки данных.' : `Високоточне Європейське вимірювальне обладнання і сучасна методика обробки даних.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Опыт сопровождения проектов термомодернизации зданий.' : `Досвід супроводу проектів термомодернізації будівель.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Опыт выполнения аудитов в проектах Европейских грантов.' : `Досвід виконання аудитів в проектах Європейських грантів.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Знание современных и актуальных материалов и оборудования.' : `Знання сучасних і актуальних матеріалів і устаткування.`}</p>
                        </div>
                        <div className={classes.rs}>
                            <img src={require('../../../img/sertif/banner11.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed2}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner12.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ЧТО НУЖНО ЗНАТЬ ОБ ЭКОНОМИИ ТЕПЛА ДЛЯ ЖИЛЫХ МНОГОКВАРТИРНЫХ ДОМОВ:' : `ЩО ПОТРІБНО ЗНАТИ СТОСОВНО ЕКОНОМІЇ ТЕПЛА ДЛЯ ЖИТЛОВИХ БАГАТОКВАРТИРНИХ БУДИНКІВ:`}</p>
                            <p className={classes.subTitle}>{this.props.language === 'RU' ? '5 ШАГОВ К УМЕНЬШЕНИЮ ЭНЕРГОПОТРЕБЛЕНИЯ ДО 30%:' : `5 КРОКІВ ДО ЗМЕНШЕННЯ ЕНЕРГОСПОЖИВАННЯ ДО 30%:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Установка домового счетчика тепла.' : `Установка будинкового лічильника тепла.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Установка АИТП(ЦО)/замена отопительного котла(инд.отпление).' : `Установка АІТП (ЦО) / заміна опалювального котла ( інд.опалення).`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Балансировка системы отопления.' : `Балансування системи опалення.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Утепление трубопроводов.' : `Утеплення трубопроводів.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Установка терморегуляторов на радиаторах МОП.' : `Установка терморегуляторів на радіаторах МОП.`}</p>
                            <p className={classes.subTitle}>{this.props.language === 'RU' ? '5 ШАГОВ К УМЕНЬШЕНИЮ ЭНЕРГОПОТРЕБЛЕНИЯ ДО 70%:' : `5 КРОКІВ ДО ЗМЕНШЕННЯ ЕНЕРГОСПОЖИВАННЯ ДО 70%:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Модернизация системы теплоснабжения.' : `Модернізація системи теплопостачання.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Утепление стен.' : `Утеплення стін.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Утепление крыши.' : `Утеплення даху.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Замена окон дверей на энергоэффективные.' : `Заміна вікон дверей на енергоефективні.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Утепление подвала.' : `Утеплення підвалу.`}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Заказать решение для ОСМД' : 'Замовити рiшення для ОСББ'}
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'НАМ ДОВЕРЯЮТ:' : 'НАМ ДОВІРЯЮТЬ:'}</h2>
                        <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={8}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 4}
                            step={1}
                            infinite={true}
                            >
                                <Slider>
                                    <Slide index={0}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/1.png')} />
                                    </Slide>
                                    <Slide index={1}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/2.png')} />
                                    </Slide>
                                    <Slide index={2}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/3.png')} />
                                    </Slide>
                                    <Slide index={3}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/4.png')} />
                                    </Slide>
                                    <Slide index={4}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/5.png')} />
                                    </Slide>
                                    <Slide index={5}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/6.png')} />
                                    </Slide>
                                    <Slide index={6}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/7.png')} />
                                    </Slide>
                                    <Slide index={7}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/8.png')} />
                                    </Slide>
                                </Slider>
                                <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                                <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                            </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OSBB)