import * as firebase from "firebase/app";
import 'firebase/storage'
import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyCYV_uN4DA6HyXCNR_16iMCdSgDZBqyiUQ",
    authDomain: "esavesite-4a63c.firebaseapp.com",
    databaseURL: "https://esavesite-4a63c.firebaseio.com",
    projectId: "esavesite-4a63c",
    storageBucket: "esavesite-4a63c.appspot.com",
    messagingSenderId: "884775206957",
    appId: "1:884775206957:web:2ec6338d5b4268c86578a4"
  };

  firebase.initializeApp(firebaseConfig);

  export default firebase