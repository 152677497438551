import React, {Component} from 'react'
import classes from './NewsSingle.module.scss'
import Fade from 'react-reveal/Fade';
import Autolinker from 'autolinker';

class NewsSingle extends Component {

    renderPars(props, minRow, maxRow) {
        return Object.values(props).map((item, index) => {
            item = Autolinker.link(item)
            while (index > minRow && index < maxRow) { 
                return (
                    <Fade key={index}>
                    <p className={classes.text} dangerouslySetInnerHTML={{__html: item}} />
                    </Fade>
                )
            }
            
        })
    }

    renderPars2(props, minRow) {
        return Object.values(props).map((item, index) => {
            item = Autolinker.link(item)
            while (index > minRow) { 
                return (
                    <Fade key={index}>
                    <p className={classes.text} dangerouslySetInnerHTML={{__html: item}} />
                    </Fade>
                )
            }
            
        })
    }

    render () {
        return (
        <div className={classes.NewsSingle}>
            <div className='wrapper'>
                <div className={classes.content}>
                    <div className={classes.ls}>
                        <p className={classes.date}>{this.props.props.date}</p>
                        <p className={classes.title}>{this.props.language === 'RU' ? this.props.props.title : this.props.props.Ukr.title}</p>
                    </div>
                    <div className={classes.rs}>
                        <img className={classes.bannerImg} src={this.props.props.bigImgUrl1} alt='banner'/>
                        {this.props.language === 'RU' ? this.renderPars(this.props.props.text, -1, 2) : this.renderPars(this.props.props.Ukr.text, -1, 2)}
                        <Fade>
                        <img className={classes.bannerImg} src={this.props.props.bigImgUrl2} alt='banner'/>
                        </Fade>
                        {this.props.language === 'RU' ? this.renderPars2(this.props.props.text, 1) : this.renderPars2(this.props.props.Ukr.text, 1)}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default NewsSingle