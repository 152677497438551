import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import classes from './Header.module.scss'
import Banner from '../Banner/Banner'
import logo from '../../img/logo_header.png'
import logo_main from '../../img/logo_header_main.png'
import facebook from '../../img/facebook_logo.png'
import {connect} from 'react-redux'
import fetchSolutions from '../../redux/actions/solutions'
import fetchServices from '../../redux/actions/services'
import setLanguage from '../../redux/actions/language'
import setModal from '../../redux/actions/modal'
import Modal from '../UI/Modal/Modal'

class Header extends Component {
    
    componentDidMount () {
        this.props.fetchSolutions()
        this.props.fetchServices()
        document.body.addEventListener('click', this.closeMenuHandler2.bind(this));
        setInterval(() => this.phoneRing(), 3000);
    }

    phoneRing() {
        let phone = document.querySelector('.fa-phone')
        phone.animate([
            { transform: 'rotate(0deg)' }, 
            { transform: 'rotate(15deg)' },
            { transform: 'rotate(30deg)' },
            { transform: 'rotate(45deg)' },
            { transform: 'rotate(30deg)' },
            { transform: 'rotate(15deg)' },
            { transform: 'rotate(0deg)' },
            { transform: 'rotate(-15deg)' },
            { transform: 'rotate(-30deg)' },
            { transform: 'rotate(-45deg)' },
            { transform: 'rotate(-30deg)' },
            { transform: 'rotate(-15deg)' },
            { transform: 'rotate(0deg)' },
          ], {
            duration: 500,
            easing: 'ease-out'
          })
          setTimeout(() => {
            phone.animate([
                { transform: 'scale(1, 1)' },
                { transform: 'scale(1.4, 1.4)' },
                { transform: 'scale(1, 1)' },
              ], {
                duration: 500,
                easing: 'ease-in-out'
              })
          }, 500);
          
    }

    sliderHeader() {
        let sliderStep = 0
        let slide = document.querySelectorAll(`.${classes.slider}`)
        let banner = document.querySelector(`.${classes.Banner}`)
        let sliderLength = slide.length
        
        let bannerTitle = {
            RU: [
                'ЭНЕРГОАУДИТ',
                'E-SAVE- ЭТО',
                'СЕРТИФИКАЦИЯ ЭНЕРГОЭФФЕКТИВНОСТИ ЗДАНИЙ',
                'НАЛИЧИЕ РАЗДЕЛА ЭНЕРГОЭФФЕКТИВНОСТИ',
            ], 
            UA: [
                'ЕНЕРГОАУДИТ',
                'E–SAVE – ЦЕ',
                'СЕРТИФІКАЦІЯ ЕНЕРГОЕФЕКТИВНОСТІ БУДІВЕЛЬ',
                'НАЯВНІСТЬ РОЗДІЛУ ЕНЕРГОЕФЕКТИВНОСТІ',
            ]}
        let bannerText = {
            RU: [
                'Первый шаг к экономии энергоресурсов до 70%',
                'Консалтинговые услуги в сфере энергоэффективности для городов, ОСМД и компаний',
                'Обязательна с 1 июля 2019 года',
                'Залог успешного прохождения экспертизы проектной документации.',
            ], 
            UA: [
                'Перший крок до економії енергоресурсів до 70%',
                'Консалтингові послуги в сфері енергоефективності для міст, ОСББ та підприємств',
                `Обов'язкова з 1 липня 2019 року`,
                'Запорука успішного проходження експертизи проектної документації.',
            ]}
            setInterval(() => {
                if (this.props.isMain === true) {
                    slide[sliderStep].classList.remove(classes.active)
                if (sliderStep < sliderLength - 1) {
                    sliderStep++
                } else {
                    sliderStep = 0
                }
            slide[sliderStep].classList.add(classes.active)
            
            if (this.props.language === 'RU') {
                banner.firstElementChild.innerHTML = bannerTitle.RU[sliderStep]
                banner.children[1].innerHTML = bannerText.RU[sliderStep]
            } else {
                banner.firstElementChild.innerHTML = bannerTitle.UA[sliderStep]
                banner.children[1].innerHTML = bannerText.UA[sliderStep]
            }
                }
            }, 5000);
    }

    openModal() {
        this.props.setModal(true)
    }

    changeLanguage() {
        if (this.props.language === 'RU') {
            this.props.setLanguage('UA')
        } else {
            this.props.setLanguage('RU')
        }
    }
    
    renderServicesLinks(items) {
        return items.map((service, index) => {
            return (
                    <NavLink
                    key={index}
                    to={'/services/page' + index}
                    exact={false}
                    onClick={this.onClickClose.bind(this)}
                    >
                        {service.name}
                    </NavLink>
            )
        })
    }
    
    renderSolutionsLinks(items) {
        return items.map((solution, index) => {
            return (
                    <NavLink
                    key={index}
                    to={'/solutions/page' + index}
                    exact={false}
                    onClick={this.onClickClose.bind(this)}
                    >
                        {solution.name}
                    </NavLink>
            )
        })
    }

    dropMenuHandler(key) {
        if (this.refs[key].classList.contains(classes.open)) {
            this.refs[key].classList.remove(classes.open)
            this.refs[key + 'Menu'].classList.remove(classes.active)
        } else {
            this.refs.solutions.classList.remove(classes.open)
            this.refs.services.classList.remove(classes.open)
            this.refs.about.classList.remove(classes.open)
            this.refs.solutionsMenu.classList.remove(classes.active)
            this.refs.servicesMenu.classList.remove(classes.active)
            this.refs.aboutMenu.classList.remove(classes.active)
            this.refs[key].classList.add(classes.open)
            this.refs[key + 'Menu'].classList.add(classes.active)
        }
    }

    closeMenuHandler2(e) {
        if (e.target !== this.refs.solutionsMenu && e.target !== this.refs.servicesMenu && e.target !== this.refs.aboutMenu && e.target !== this.refs.i && e.target !== this.refs.i2 && e.target !== this.refs.i3)
        {
            this.refs.solutions.classList.remove(classes.open)
            this.refs.services.classList.remove(classes.open)
            this.refs.solutionsMenu.classList.remove(classes.active)
            this.refs.servicesMenu.classList.remove(classes.active)
            this.refs.about.classList.remove(classes.open)
            this.refs.aboutMenu.classList.remove(classes.active)
        }
    }

    closeMenuHandler() {
        this.refs.solutions.classList.remove(classes.open)
        this.refs.services.classList.remove(classes.open)
        this.refs.about.classList.remove(classes.open)
        this.refs.solutionsMenu.classList.remove(classes.active)
        this.refs.aboutMenu.classList.remove(classes.active)
        this.refs.servicesMenu.classList.remove(classes.active)
    }

    menuToggleHandler() {
        if (this.refs.headerLinks.classList.contains(classes.opened)) {
            this.refs.headerLinks.classList.remove(classes.opened)
            this.refs.bg.classList.remove(classes.opened)
        } else {
            this.refs.headerLinks.classList.add(classes.opened)
            this.refs.bg.classList.add(classes.opened)
        }
    }

    onClickClose () {
        this.closeMenuHandler();
        this.menuToggleHandler();
    }

    
    render () {
        return (
            <header className={ this.props.isMain === true ? classes.Header + ' ' + classes.banner : classes.Header}>
                <div className={classes.bgColor} onClick={this.menuToggleHandler.bind(this)} ref='bg'></div>
                <div className='wrapper'>
                    <nav className={classes.headerMain}>
                        <NavLink
                        className={classes.headerImage}
                        to='/'
                        exact={true}
                        >
                        <img 
                        src={this.props.isMain === true ? logo_main : logo } 
                        alt='header_logo'
                        ></img>
                        </NavLink>
                        <i className={"fas fa-bars " + classes.menuIcon} onClick={this.menuToggleHandler.bind(this)}></i>
                        <div className={classes.headerLinks} ref='headerLinks'>
                            <i className={"fas fa-times " +  classes.menuIcon + ' ' + classes.menuClose} onClick={this.menuToggleHandler.bind(this)}></i>
                            <div 
                            className={classes.dropMenu}
                            >
                                <p 
                                className={this.props.isMain === true ? classes.navLink + ' ' + classes.mainLink : classes.navLink}
                                onClick={this.dropMenuHandler.bind(this, 'about')}
                                ref='aboutMenu'
                                >
                                    {this.props.language === 'RU' ? 'О нас' : 'Про нас'}
                                    <i ref='i3' className="fas fa-chevron-down"></i>
                                </p>
                                <div
                                className={classes.onDropMenu}
                                ref='about'
                                >
                                    <a
                                    onClick={this.menuToggleHandler.bind(this)}
                                    href='/#about'
                                    >
                                    {this.props.language === 'RU' ? 'О компании' : 'Про компанію'}
                                    </a>
                                    <NavLink
                                    onClick={this.menuToggleHandler.bind(this)}
                                    to='/news'
                                    >
                                    {this.props.language === 'RU' ? 'Новости' : 'Новини'}
                                    </NavLink>
                                </div>
                            </div>
                            <div 
                            className={classes.dropMenu}
                            >
                                <p 
                                className={this.props.isMain === true ? classes.navLink + ' ' + classes.mainLink : classes.navLink}
                                onClick={this.dropMenuHandler.bind(this, 'services')}
                                ref='servicesMenu'
                                >
                                    {this.props.language === 'RU' ? 'Услуги' : 'Послуги'}
                                    <i ref='i2' className="fas fa-chevron-down"></i>
                                </p>
                                <div
                                className={classes.onDropMenu}
                                ref='services'
                                >
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/sertificat'
                                    >
                                    {this.props.language === 'RU' ? 'Энергосертификат' : 'Енергосертифікат'}
                                </NavLink>
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/energoeffect'
                                    >
                                    {this.props.language === 'RU' ? 'Раздел "Энергоэффективность"' : 'Розділ "Енергоефективність"'}
                                </NavLink>
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/passport'
                                    >
                                    {this.props.language === 'RU' ? 'Энергопаспорт' : 'Енергопаспорт'}
                                </NavLink>
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/audit'
                                    >
                                    {this.props.language === 'RU' ? 'Энергоаудит' : 'Енергоаудит'}
                                </NavLink>
                                </div>
                            </div>
                            <div 
                            className={classes.dropMenu}
                            >
                                <p 
                                className={this.props.isMain === true ? classes.navLink + ' ' + classes.mainLink : classes.navLink}
                                onClick={this.dropMenuHandler.bind(this, 'solutions')}
                                ref='solutionsMenu'
                                >
                                    {this.props.language === 'RU' ? 'Решения' : 'Рiшення'}
                                    <i ref='i' className="fas fa-chevron-down"></i>
                                </p> 
                                <div
                                className={classes.onDropMenu}
                                ref='solutions'
                                >
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/osbb'
                                    >
                                    {this.props.language === 'RU' ? 'ОСМД' : 'ОСББ'}
                                </NavLink>
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/otg'
                                    >
                                    {this.props.language === 'RU' ? 'ОТГ' : 'ОТГ'}
                                </NavLink>
                                <NavLink
                                    exact={false}
                                    onClick={this.onClickClose.bind(this)}
                                    to='/developer'
                                    >
                                    {this.props.language === 'RU' ? 'Застройщикам' : 'Забудовникам'}
                                </NavLink>
                                </div>
                            </div>
                            <NavLink
                            className={this.props.isMain === true ? classes.navLink + ' ' + classes.mainLink : classes.navLink}
                            onClick={this.menuToggleHandler.bind(this)}
                            key={Math.random()}
                            to='/contacts#contacts'
                            >
                                {this.props.language === 'RU' ? 'Контакты' : 'Контакти'}
                            </NavLink>
                            <NavLink
                            className={this.props.isMain === true ?classes.navLink  + ' ' + classes.last + ' ' + classes.mainLink : classes.navLink  + ' ' + classes.last}
                            onClick={this.menuToggleHandler.bind(this)}
                            to='/contacts#form'
                            >
                            {this.props.language === 'RU' ? 'Оставить заявку' : 'Залишити заявку'}
                            </NavLink>
                            <div className={classes.icon}>
                                <a className={classes.facebook} rel="noopener noreferrer" target='_blank' href='http://facebook.com/esave.com.ua'><img src={facebook} alt='facebook logo' /></a>
                                <div className={classes.setLanguage} onClick={this.changeLanguage.bind(this)}>
                                    <p className={classes.language}>{this.props.language}</p>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {this.props.isMain === true
                    ?
                    <Banner 
                    classes={classes}
                    language = {this.props.language}
                    openModal={this.openModal.bind(this)}
                    sliderHeader={this.sliderHeader.bind(this)}
                    />
                    :
                    null
                    }
                </div>
                <div
                    className={this.props.isContacts === true ? classes.contactLinkDisable : classes.contactLink}
                    onClick={this.openModal.bind(this)}
                    >
                    <i className="fas fa-phone"></i>
                </div>
                <div className={classes.slider + ' ' + classes.slide1 + ' ' + classes.active}></div>
                <div className={classes.slider + ' ' + classes.slide2}></div>
                <div className={classes.slider + ' ' + classes.slide3}></div>
                <div className={classes.slider + ' ' + classes.slide4}></div>
                <Modal />
            </header>
        )
    }
}

function mapStateToProps(state) {
    return {
        solutions: state.solutions,
        services: state.services,
        isMain: state.mainPage.isMainPage,
        isContacts: state.contactsPage.isContactsPage,
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchSolutions: () => dispatch(fetchSolutions()),
        fetchServices: () => dispatch(fetchServices()),
        setLanguage: (language) => dispatch(setLanguage(language)),
        setModal: (modal) => dispatch(setModal(modal)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)