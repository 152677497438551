import React, {Component} from 'react'
import classes from '../Sertificat/Sertificat.module.scss'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import setModal from '../../../redux/actions/modal'

class Audit extends Component {

    openModal() {
        this.props.setModal(true)
    }

    render () {

        return (
        <div className={classes.Sertificat}>
            <div className="wrapper">
                <div className={classes.content}>
                    <h1 className={classes.title}>{this.props.language === 'RU' ? 'ЭНЕРГОАУДИТ' : 'ЭНЕРГОАУДИТ'}</h1>
                    <h2 className={classes.titleText}>{this.props.language === 'RU' ? 'ЗАКАЗЫВАЯ ЭНЕРГОАУДИТ У НАС ВЫ ГАРАНТИРОВАННО ПОЛУЧАЕТЕ:' : 'ЗАМОВЛЯЮЧИ ЕНЕРГОАУДИТ У НАС ВИ ГАРАНТОВАНО ОТРИМУЄТЕ:'}</h2>
                    <div className={classes.icons}>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon16.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'СОВРЕМЕННЫЕ ПОДХОДЫ К ИНСТРУМЕНТАЛЬНОМУ ОБСЛЕДОВАНИЮ ЗДАНИЙ' : 'Сучасні підходи до інструментального обстеження будівель'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon3.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ВЫСОКОЕ КАЧЕСТВО АУДИТОВ ОТ АТТЕСТОВАННЫХ ЭКСПЕРТОВ' : 'Найвищу якість аудиту від атестованих експертів'}</p>
                        </div>
                        <div className={classes.icon}>
                            <img src={require('../../../img/sertif/icon17.png')} alt='icon'/>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'ГАРАНТИРОВАННОЕ СНИЖЕНИЕ ПОТРЕБЛЕНИЯ ЭНЕРГОРЕСУРСОВ ПОСЛЕ ВНЕДРЕНИЯ МЕРОПРИЯТИЙ' : 'Гарантоване зниження споживання енергоресурсів після впровадження заходів'}</p>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                    </div>
                    <div className={classes.about}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ПОЧЕМУ СТОИТ ВЫБРАТЬ НАС' : 'ЧОМУ ВАРТО ВИБРАТИ НАС'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Особенностью энергоаудита от компании E-SAVE является использование высокоточного измерительного оборудования и современной методики, что позволяет получить достоверные результаты обследования зданий с минимальной погрешностью. Расчеты, выполненные после сбора данных, регламентированы действующим законодательством и полностью соответствуют всем новым стандартам и требованиям.' : 'Особливістю енергоаудиту від компанії E - SAVE є використання високоточного вимірювального обладнання і сучасної методики, що дозволяє отримати достовірні результати обстеження будівель з мінімальною похибкою. Розрахунки, виконані після збору даних, регламентовані чинним законодавством і повністю відповідають всім новим стандартам і вимогам.'}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Использование специализированного программного обеспечения, а также практический опыт термомодернизации, позволяет значительно ускорить процесс формирования рекомендаций по результатам энергоаудита (3 недели по сравнению с 1,5-2 месяцами, которые обычно необходимы другим компаниям). Общее количество энергоаудитов, выполненных нашими экспертами, составляет более 1000 для зданий жилого, муниципального, коммерческого назначения, школ и детсадов.' : 'Використання спеціалізованого програмного забезпечення, а також практичний досвід термомодернізації, дозволяє значно прискорити процес формування рекомендацій за результатами енергоаудиту (3 тижні в порівнянні з 1,5-2 місяцями, які зазвичай необхідні іншим компаніям). Загальна кількість енергоаудитів, виконаних нашими експертами, складає понад 1000 для будівель житлового, муніципального, комерційного призначення, шкіл і дитсадків.'}</p>
                        </div>
                        <div className={classes.rs}>
                        <img src={require('../../../img/sertif/banner6.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed5}>
                        <div className={classes.ls}>
                            <img src={require('../../../img/sertif/banner7.jpg')} alt='banner'/>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'E-SAVE - ЭТО:' : `E-SAVE - ЦЕ:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Аттестованные эксперты по энергосертификации зданий и обследованию инженерных систем.' : `Атестовані експерти з енергосертифікаціі будівель і обстеження інженерних систем.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Аналитики в сфере энергоэффективного строительства (сертифицированные Американским институтом BPI «Building performance institute»).' : `Аналітики в сфері енергоефективного будівництва (сертифіковані Американським інститутом BPI «Building performance institute»).`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Сертифицированные энергоменеджеры (EUREM, UNIDO).' : `Сертифіковані енергоменеджери (EUREM, UNIDO).`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Знание и соблюдение всех ДСТУ, ДБН.' : `Знання та дотримання всіх ДСТУ, ДБН.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Современная методика обработки данных.' : `Сучасна методика обробки даних.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Выполнение комплексных энергоаудитов под термомодернизацию зданий.' : `Виконання комплексних енергоаудитів під термомодернізацію будівель.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Опыт проведения аудитов для программ международных инвестиций.' : `Досвід проведення аудитів для програм міжнародних інвестицій .`}</p>
                        </div>
                    </div>
                    <div className={classes.whoNeed + ' ' + classes.whoNeed3 + ' ' + classes.whoNeed4} style={{marginTop: 30 + 'px'}}>
                        <div className={classes.ls}>
                            <p className={classes.titleSide}>{this.props.language === 'RU' ? 'ЭНЕРГОАУДИТ НЕОБХОДИМ:' : `ЕНЕРГОАУДИТ НЕОБХІДНИЙ:`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Для определения первоочередных инвестиций, которые дадут видимый эффект и позволят серьезно снизить затраты на энергоресурсы.' : `Для визначення першочергових інвестицій, які дадуть видимий ефект і дозволять серйозно знизити витрати на енергоресурси.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Для получения бюджетных средств и грантов на термомодернизацию.' : `Для отримання бюджетних коштів та грантів на термомодернізацію.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Для участия в программах компенсации средств (Фонд энергоэффективности).' : `Для участі в програмах компенсації коштів (Фонд енергоефективності).`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Для улучшения осведомленности жителей или работников учреждений об энергоэффективности.' : `Для поліпшення обізнаності жителів або працівників установ про енергоефективність.`}</p>
                            <p className={classes.text}>{this.props.language === 'RU' ? 'Создание инвестиционной привлекательности объекта.' : `Створення інвестиційної привабливості об'єкта.`}</p>
                        </div>
                        <div className={classes.rs}>
                            <img src={require('../../../img/sertif/banner8.jpg')} alt='banner'/>
                        </div>
                    </div>
                    <div
                    onClick={this.openModal.bind(this)}
                    className={classes.link}
                    >
                            {this.props.language === 'RU' ? 'Заказать энергоаудит' : 'Замовити енергоаудит'}
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'МЫ УЖЕ ПРОВЕЛИ ЭНЕРГОАУДИТ ДЛЯ:' : 'МИ ВЖЕ ПРОВЕЛИ ЕНЕРГОАУДИТ ДЛЯ:'}</h2>
                        <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={4}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 3}
                            step={1}
                            infinite={true}
                            >
                                <Slider>
                                    <Slide index={0}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/9.png')} />
                                    </Slide>
                                    <Slide index={1}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/10.png')} />
                                    </Slide>
                                    <Slide index={2}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/12.png')} />
                                    </Slide>
                                    <Slide index={3}>
                                        <Image className={classes.slideImg} src={require('../../../img/sertif/clientLogo/11.png')} />
                                    </Slide>
                                </Slider>
                                <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                                <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                            </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Audit)