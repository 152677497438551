import {CHANGE_NEWS, CHANGE_IMG, RESET_NEWS_CHANGING} from './actionTypes'
import axios from '../../axios/axios'
import firebase from '../../axios/firebase'

export function changeNews (item) {
    return {
        type: CHANGE_NEWS,
        item
    }
}

export function resetNewsChanging () {
    alert ('Новость изменена!')
    return {
        type: RESET_NEWS_CHANGING
    }
}

export function finishChangeNews(id) {
    return async (dispatch, getState) => {
        console.log(id)
        const token = getState().auth.token
        if (getState().changeNews.news.isTitle === true) {
            Object.entries(getState().news.news).map((item, index) => {
                if (item[1].isTitle === true) {
                    console.log(item)
                    item[1].isTitle = false
                    delete item[1].bigImgUrl1
                    delete item[1].bigImgUrl2
                    delete item[1].mediumImgUrl
                    delete item[1].shortImgUrl
                    delete item[1].titleImgUrl
                    axios.put(`/news/${item[0]}.json`, item[1], {
                        params: {auth: token}
                    })
                }
            })
        }
        await axios.put(`/news/${id}.json`, getState().changeNews.news, {
            params: {auth: token}
        })
        const files = getState().changeNews.img
        Object.values(files).forEach((item) => {
            if (item) {
                firebase.storage().ref('news/'+ item.name).put(item);
            }
        })
        dispatch(resetNewsChanging())
    }
}

export function changeImg (img) {
    return {
        type: CHANGE_IMG,
        img
    }
}