import React, {Component} from 'react'
import Service from '../../components/Service/Service'
import Loader from '../../components/UI/Loader/Loader'
import  fetchServices  from '../../redux/actions/services'
import {connect} from 'react-redux'

class Services extends Component {

    componentDidMount () {
        this.props.fetchServices()
    }

    render () {
        return (
            <>
                {
                    this.props.services.loading
                    ?
                    <Loader />
                    :
                    <Service 
                    id={[this.props.match.params.id]}
                    props={this.props.services.services[this.props.match.params.id]}
                    propsUkr={this.props.services.servicesUkr[this.props.match.params.id]}
                    language={this.props.language}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        services: state.services,
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchServices: () => dispatch(fetchServices()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)