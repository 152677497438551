import React, {Component } from 'react'
import classes from './Layout.module.scss'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

class Layout extends Component {

    render () {
        return (
            <div className={classes.Layout}>
                <Header closeMenu={this.closeMenuHandler2}/>
                <main >
                    {this.props.children }
                </main>
                <Footer />
            </div>
        )
    }
}

export default Layout