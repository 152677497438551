import React, {Component} from 'react'
import ProjectSingle from '../../components/ProjectSingle/ProjectSingle'
import Loader from '../../components/UI/Loader/Loader'
import  fetchProjects  from '../../redux/actions/projects'
import {connect} from 'react-redux'


class Project extends Component {

    componentDidMount () {
        this.props.fetchProjects()
    }

    render () {
        return (
            <>
                {
                    this.props.projects.loading
                    ?
                    <Loader />
                    :
                    <ProjectSingle
                    id={[this.props.match.params.id]}
                    props={this.props.projects.projects[this.props.match.params.id]}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchProjects: () => dispatch(fetchProjects()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)