import React from 'react'
import classes from './NewsMain.module.scss'
import Loader from '../UI/Loader/Loader'

const NewsMain = (props) => (
    <div className={classes.NewsMain}>
        <div className={'wrapper'}>
            <div className={classes.header}>
                <p className={classes.title}>
                {props.language === 'RU' ? 'новости' : 'новини'} 
                </p>
            </div>
            { props.news.loading
            ?
            <Loader />
            :
            
            props.renderNews(props, classes, props.news.currentPage)
            
            }
        </div>
    </div>
)

export default NewsMain