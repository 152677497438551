import React, {Component} from 'react'
import {connect} from 'react-redux'
import  fetchNews  from '../../redux/actions/news'
import Loader from '../UI/Loader/Loader'
import classes from './ChangeNews.module.scss'
import { changeNews, finishChangeNews, changeImg } from '../../redux/actions/changeNews'

class ChangeNews extends Component {

    state = {
        isChange: false
    }


    componentDidMount () {
        this.props.fetchNews()      
    }

    submitHandler = event => {
        event.preventDefault()
      }

      createNewsOnServer () {
        const news = {
            title: this.state.title,
            text: this.state.text,
            isTitle: this.state.isTitle,
            Ukr: this.state.Ukr
        }
        const img = {
            banner1: this.state.banner1,
            banner2: this.state.banner2,
            img1: this.state.img1,
            img2: this.state.img2,
            img3: this.state.img3,
        }
        console.log(news)
        console.log(img)
        this.props.changeNews(news)
        this.props.changeImg(img)
        this.props.finishChangeNews(this.props.match.params.id)
    }

      onChangeHandler = (event) => {
        const name = event.target.name
        const value = event.target.value
        const id = event.target.id
        if (event.target.id === 'titleRU') {
            this.setState ({
                [name]: value 
            })
        } else if (event.target.id === 'titleUA') {
            let Ukr = {...this.state.Ukr, [name]: value}
            this.setState ({
               Ukr
            })
        } else if (event.target.id === 'textru') {
            let text = {...this.state.text, [name]: value}
            this.setState ({
               text
            })
        } else if (event.target.id === 'textua') {
            let text = {...this.state.Ukr.text, [name]: value}
            let Ukr = {...this.state.Ukr, text}
            this.setState ({
               Ukr
            })
        } else if (name === 'isTitle' && value === 'true') {
            this.setState ({
                [name]: true
            })
        } else if (name === 'isTitle' && value === 'false') {
            this.setState ({
                [name]: false
            })
        } else if (name  === 'file') {
            let file = event.target.files[0]
            let blob = file.slice(0, file.size, file.type)
            if (id === "banner1") {
                let newFile = new File([blob], 'big-img-' + this.props.match.params.id + '-0.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "banner2") {
                let newFile = new File([blob], 'big-img-' + this.props.match.params.id + '-1.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "img1") {
                let newFile = new File([blob], 'title-img-' + this.props.match.params.id + '.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "img2") {
                let newFile = new File([blob], 'medium-img-' + this.props.match.params.id + '.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "img3") {
                let newFile = new File([blob], 'short-img-' + this.props.match.params.id + '.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } 
        } else {
            this.setState ({
                [name]: value 
            })
        }
    }

    startChange() {
        let title = this.props.news.news[this.props.match.params.id].title
        let text = this.props.news.news[this.props.match.params.id].text
        let date = this.props.news.news[this.props.match.params.id].date
        let isTitle = this.props.news.news[this.props.match.params.id].isTitle
        let id = this.props.news.news[this.props.match.params.id].id
        let Ukr = this.props.news.news[this.props.match.params.id].Ukr
        this.setState ({
            isChange: true,
            title,
            text,
            date,
            isTitle,
            id,
            Ukr
        })
    }

    renderNews () {
        let news = this.props.news.news[this.props.match.params.id]
            if (this.state.isChange === false) {
                return (
                    <p className={classes.titleTet} onClick={this.startChange.bind(this)}>Начать изменение</p>
                )
            } else {
                return (
                    <form ref='formChange' className={classes.formChange} onSubmit={this.submitHandler}>
                        <div className={classes.inputs}>
                            <div className={classes.aside}>
                            <p>Заголовок:</p>
                                <input defaultValue={news.title} name='title' type='text' id='titleRU' onChange={this.onChangeHandler}/>
                                {news.text.map((news, index) => {
                                    return (
                                        <div key={index}>
                                        <p>Текст:</p>
                                        <input className={classes.inputText} defaultValue={news} name={index} type='text' id='textru' onChange={this.onChangeHandler}/>
                                        </div>
                                    )
                                })  
                                }
                            </div>
                            <div className={classes.aside}>
                            <p>Заголовок:</p>
                                <input defaultValue={news.Ukr.title} name='title' type='text' id='titleUA' onChange={this.onChangeHandler}/>
                                {news.Ukr.text.map((news, index) => {
                                    return (
                                        <div key={index}>
                                        <p>Текст:</p>
                                        <input className={classes.inputText} defaultValue={news} name={index} type='text' id='textua' onChange={this.onChangeHandler}/>
                                        </div>
                                    )
                                })  
                                }
                            </div>
                        </div>
                        
                        <p>Основная?</p>
                        {
                            news.isTitle
                            ?
                            <div>
                            <label className={classes.radio} htmlFor="option1">Нет </label>
                            <input type="radio" name="isTitle" id="option1" value="false" onChange={this.onChangeHandler}/>   
                            <label className={classes.radio} htmlFor="option2">Да </label>
                            <input type="radio" name="isTitle" id="option2" value="true" defaultChecked onChange={this.onChangeHandler}/>
                            </div>
                            :
                            <div>
                            <label className={classes.radio} htmlFor="option1">Нет </label>
                            <input type="radio" name="isTitle" id="option1" value="false" defaultChecked onChange={this.onChangeHandler}/>   
                            <label className={classes.radio} htmlFor="option2">Да </label>
                            <input type="radio" name="isTitle" id="option2" value="true" onChange={this.onChangeHandler}/>
                            </div>
                        }
    
                            <label htmlFor="banner1">Изменить первый баннер (размер 1100 х 500)</label>
                            <input type="file" id="banner1" name="file" onChange={this.onChangeHandler}></input>
                            <label htmlFor="banner2">Изменить второй баннер (размер 1100 х 500)</label>
                            <input type="file" id="banner2" name="file" onChange={this.onChangeHandler}></input>
                            <label htmlFor="img1">Изменить титульное изображение (размер 980 х 600)</label>
                            <input type="file" id="img1" name="file" onChange={this.onChangeHandler}></input>
                            <label htmlFor="img2">Изменить среднее изображение (размер 890 х 480)</label>
                            <input type="file" id="img2" name="file" onChange={this.onChangeHandler}></input>
                            <label htmlFor="img3">Изменить маленькое изображение (размер 460 х 330)</label>
                            <input type="file" id="img3" name="file" onChange={this.onChangeHandler}></input>
                            <button type='submit' onClick={this.createNewsOnServer.bind(this)}>Отправить</button>
                    </form>
                )
            }
            
    }

    render () {
        return (
            <div className={classes.ChangeNews}>
                <div className='wrapper'>
                {
                    this.props.news.loading
                    ?
                    <Loader />
                    :
                    this.renderNews()
                }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        news: state.news
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNews: () => dispatch(fetchNews()),
        changeNews: item => dispatch(changeNews(item)),
        changeImg: img => dispatch(changeImg(img)),
        finishChangeNews: (id) => dispatch(finishChangeNews(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNews) 