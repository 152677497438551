import React, {Component} from 'react'
import classes from './CreateNews.module.scss'
import {connect} from 'react-redux'
import  fetchNews  from '../../redux/actions/news'
import { createNews, finishCreateNews, uploadImg } from '../../redux/actions/createNews'
import Loader from '../UI/Loader/Loader'

class CreateNews extends Component {

    state = {
        text: {},
        isTitle: false,
        input: [0, 1, 2],
        Ukr: {
            text: {},
            title: undefined
        }
    }

    componentDidMount () {
        this.props.fetchNews()
        let d=new Date();
        let day=d.getDate();
        let month=d.getMonth() + 1;
        let year=d.getFullYear();
        let date = day + "." + month + "." + year
        this.setState ({
            date
        })
    }

    submitHandler = event => {
        event.preventDefault()
      }

    onChangeHandler = (event) => {
        const name = event.target.name
        const value = event.target.value
        const id = event.target.id
        const newsLength = Object.keys(this.props.news.news).length
        if (event.target.id === 'titleRU') {
            this.setState ({
                [name]: value 
            })
        } else if (event.target.id === 'titleUA') {
            let Ukr = {...this.state.Ukr, [name]: value}
            this.setState ({
               Ukr
            })
        } else if (event.target.id === 'textru') {
            let text = {...this.state.text, [name]: value}
            this.setState ({
               text
            })
        } else if (event.target.id === 'textua') {
            let text = {...this.state.Ukr.text, [name]: value}
            let Ukr = {...this.state.Ukr, text}
            this.setState ({
               Ukr
            })
        } else if (name === 'isTitle' && value === 'true') {
            this.setState ({
                [name]: true
            })
        } else if (name === 'isTitle' && value === 'false') {
            this.setState ({
                [name]: false
            })
        } else if (name  === 'file') {
            let file = event.target.files[0]
            let blob = file.slice(0, file.size, file.type)
            if (id === "banner1") {
                let newFile = new File([blob], 'big-img-' + (newsLength) + '-0.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "banner2") {
                let newFile = new File([blob], 'big-img-' + (newsLength) + '-1.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "img1") {
                let newFile = new File([blob], 'title-img-' + (newsLength) + '.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "img2") {
                let newFile = new File([blob], 'medium-img-' + (newsLength) + '.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } else if (id === "img3") {
                let newFile = new File([blob], 'short-img-' + (newsLength) + '.png', {type: 'image/png'});
                this.setState ({
                    [id]: newFile
                })
            } 
        } 
    }

    createNewsOnServer () {
        const news = {
            date: this.state.date,
            title: this.state.title,
            text: this.state.text,
            isTitle: this.state.isTitle,
            Ukr: this.state.Ukr
        }
        const img = {
            banner1: this.state.banner1,
            banner2: this.state.banner2,
            img1: this.state.img1,
            img2: this.state.img2,
            img3: this.state.img3,
        }
        console.log(news)
        console.log(img)
        if (this.state.title === undefined || 
            this.state.text[0] === undefined || 
            this.state.text[1] === undefined || 
            this.state.text[2] === undefined || 
            this.state.banner1 === undefined ||
            this.state.banner2 === undefined ||
            this.state.img1 === undefined ||
            this.state.img2 === undefined ||
            this.state.img3 === undefined ||
            this.state.Ukr.title === undefined ||
            this.state.Ukr.text[0] === undefined ||
            this.state.Ukr.text[1] === undefined ||
            this.state.Ukr.text[2] === undefined) 
            {
            alert('Заполните все поля!')
        } else {
            this.props.createNews(news)
            this.props.uploadImg(img)
            this.props.finishCreateNews()
            this.refs.formCreate.reset()
        }
    }

    inputText(input, par) {
        return input.map((index) => {
            return (
                <input placeholder='Текст' key={index} name={index} type='text' id={'text' + par} required onChange={this.onChangeHandler}></input>
            )
        })
    }

    newInput() {
        let n = this.state.input.length
        let input = [...this.state.input]
        input.push(n)
        this.setState ({
            input
        })
    }

    render () {
        return (
            <div className={classes.CreateNews}>
                <div className='wrapper'>
                {
                    this.props.news.loading
                    ?
                    <Loader />
                    :
                    <form ref='formCreate' className={classes.formCreate} onSubmit={this.submitHandler}>
                        <div className={classes.inputs}>
                            <div className={classes.aside}>
                                <p>Русская версия</p>
                                <input placeholder='Заголовок' id='titleRU' name='title' type='text' required onChange={this.onChangeHandler}></input>
                                {this.inputText(this.state.input, 'ru')}
                            </div>
                            <div className={classes.aside}>
                                <p>Украинская версия</p>
                                <input placeholder='Заголовок' id='titleUA' name='title' type='text' required onChange={this.onChangeHandler}></input>
                                {this.inputText(this.state.input, 'ua')}
                            </div>
                        </div>
                        <div className={classes.button} onClick={this.newInput.bind(this)}>Добавить абзац</div>
                        
                        <label htmlFor="banner1">Прикрепите первый баннер (размер 1100 х 500)</label>
                        <input type="file" id="banner1" name="file" required onChange={this.onChangeHandler}></input>
                        <label htmlFor="banner2">Прикрепите второй баннер (размер 1100 х 500)</label>
                        <input type="file" id="banner2" name="file" required onChange={this.onChangeHandler}></input>
                        <label htmlFor="img1">Прикрепите титульное изображение (размер 980 х 600)</label>
                        <input type="file" id="img1" name="file" required onChange={this.onChangeHandler}></input>
                        <label htmlFor="img2">Прикрепите среднее изображение (размер 890 х 480)</label>
                        <input type="file" id="img2" name="file" required onChange={this.onChangeHandler}></input>
                        <label htmlFor="img3">Прикрепите маленькое изображение (размер 460 х 330)</label>
                        <input type="file" id="img3" name="file" required onChange={this.onChangeHandler}></input>

                        <p>Сделать основной?</p>
                        <label className={classes.radio} htmlFor="option1">Нет </label>
                        <input type="radio" name="isTitle" id="option1" value="false" checked onChange={this.onChangeHandler}/>   
                        <label className={classes.radio} htmlFor="option2">Да </label>
                        <input type="radio" name="isTitle" id="option2" value="true" onChange={this.onChangeHandler}/>

                        <button type='submit' onClick={this.createNewsOnServer.bind(this)}>Отправить</button>
                    </form>
                }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        news: state.news
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNews: () => dispatch(fetchNews()),
        createNews: item => dispatch(createNews(item)),
        uploadImg: img => dispatch(uploadImg(img)),
        finishCreateNews: () => dispatch(finishCreateNews()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNews)