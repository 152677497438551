import {SET_LANGUAGE} from '../actions/actionTypes'

const initialState = {
    language: 'RU',
}

export default function languageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            }
        default: 
        return state
    }
}