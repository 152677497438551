import React, {Component} from 'react'
import NewsSingle from '../../components/NewsSingle/NewsSingle'
import Loader from '../../components/UI/Loader/Loader'
import  fetchNews  from '../../redux/actions/news'
import {connect} from 'react-redux'


class News extends Component {

    componentDidMount () {
        this.props.fetchNews()
    }

    render () {
        return (
            <>
                {
                    this.props.news.loading
                    ?
                    <Loader />
                    :
                    <NewsSingle
                    id={[this.props.match.params.id]}
                    props={this.props.news.news[this.props.match.params.id]}
                    language={this.props.language}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        news: state.news,
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNews: () => dispatch(fetchNews()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News)