import {SET_LANGUAGE} from './actionTypes'

export function Language(language) {
    return {
        type: SET_LANGUAGE,
        language
    }
}

export default function setLanguage(language) {
    return (dispatch) => {
        dispatch(Language(language))
    }
}