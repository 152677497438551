import React, {Component} from 'react'
import classes from './Modal.module.scss'
import {connect} from 'react-redux'
import axios from 'axios'
import Zoom from 'react-reveal/Zoom';
import setModal from '../../../redux/actions/modal'
const API_PATH = 'https://esave.com.ua/mail.php'


class Modal extends Component {

    state = {
        name: null, 
        phone: null,
        firstStep: true
    }

    telValid = () => {
        window.addEventListener("DOMContentLoaded", function() {
            function setCursorPosition(pos, elem) {
                elem.focus();
                if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
                else if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.collapse(true);
                    range.moveEnd("character", pos);
                    range.moveStart("character", pos);
                    range.select()
                }
            }
            
            function mask(event) {
                var matrix = "+38 (___) ___ ____",
                    i = 0,
                    def = matrix.replace(/\D/g, ""),
                    val = this.value.replace(/\D/g, "");
                if (def.length >= val.length) val = def;
                this.value = matrix.replace(/./g, function(a) {
                    return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
                });
                if (event.type === "blur") {
                    if (this.value.length === 2) this.value = ""
                } else setCursorPosition(this.value.length, this)
            };
                var input = document.querySelector("#form_tel");
                input.addEventListener("input", mask, false);
                input.addEventListener("focus", mask, false);
                input.addEventListener("blur", mask, false);
            });
    }
    
    closeModal(e) {
        if (e.target !== this.refs.cl1 &&
            e.target !== this.refs.cl2 &&
            e.target !== this.refs.getform &&
            e.target !== this.refs.cl4 &&
            e.target !== this.refs.cl5 &&
            e.target !== this.refs.cl6 &&
            e.target !== this.refs.cl7 &&
            e.target !== this.refs.cl8) 
            {
                this.props.setModal(false)
            }
    }

    onChangeHandler = (event) => {
        console.log(event.target.name, event.target.value)
        const name = event.target.name
        const value = event.target.value
        this.setState ({
            [name]: value
        })
    }

    submitHandler = event => {
        event.preventDefault()
        console.log(this.state)
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
          })
            .then(
                this.refs.getform.reset()
            )
            .then(
                this.setState ({
                    firstStep: false
                })
            )
            .catch(error => this.setState({ error: error.message }));
    }
    render () {
        return (
            this.props.modal === true
                ?
                <div className={classes.Modal} onClick={this.closeModal.bind(this)}>
                    <div className='wrapper'>
                        <div className={classes.getCallBack + ' ' + (this.state.firstStep ? classes.active: classes.unactive)} id='form' ref='form'>
                            <Zoom>
                            <div ref='cl1' className={classes.rs}>
                                <i onClick={this.closeModal.bind(this)} className={'fas fa-times ' + classes.close}></i>
                                <p ref='cl2' className={classes.headTitle}>{this.props.language === 'RU' ? 
                                'Связаться с нами' : 
                                `Зв'язатися з нами`
                                }</p>
                                <form className={classes.form} onSubmit={this.submitHandler} ref='getform'>
                                    <p ref='cl4' className={classes.title}>{this.props.language === 'RU' ? 
                                    'Ваши контакты' : 
                                    'Вашi контакти'
                                    }</p>
                                    <section ref='cl5' className={classes.textForm}>
                                        <input ref='cl6' required name='name' type='text' placeholder={this.props.language === 'RU' ? 'Имя' : `Ім'я`} onChange={event => this.onChangeHandler(event)}/>
                                        <input ref='cl7' required name='phone' type='tel' id='form_tel' placeholder='Телефон' onChange={event => this.onChangeHandler(event)}/>
                                    </section>
                                    <button ref='cl8' type='submit'>{this.props.language === 'RU' ? 
                                    'Отправить' : 
                                    'Вiдправити'
                                    }</button>
                                </form>
                            </div>
                            </Zoom>
                        </div>
                        <div className={classes.getCallBack + ' ' + (this.state.firstStep ? classes.unactive: classes.active)} id='form' ref='form'>
                            <Zoom>
                            <div ref='cl1' className={classes.rs}>
                                <i onClick={this.closeModal.bind(this)} className={'fas fa-times ' + classes.close}></i>
                                <div className={classes.thank}>
                                    <h2>Спасибо за заявку! <br />Менеджер свяжется с Вами в ближайшее время</h2>
                                </div>
                            </div>
                            </Zoom>
                        </div>
                    </div>
                </div>
                : 
                null
    )
}}

function mapStateToProps(state) {
    return {
        language: state.language.language,
        modal: state.modal.modal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setModal: (modal) => dispatch(setModal(modal)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
