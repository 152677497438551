import React, {Component} from 'react'
import classes from './Sertificate.module.scss'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {connect} from 'react-redux'

class Sertificate extends Component {

    render () {
        return (
            <div className={classes.Sertificate}>
                <div className="wrapper">
                    <div className={classes.Sertif}>
                        <h2 className={classes.title}></h2>
                        <div className={classes.content}>
                            <div className={classes.item}>
                                <img src={require('../../img/ser1.jpg')} alt='sertificate'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'Квалификационный аттестат на право проведения энергоаудита и сертификации энергоэффективности зданий' : 'Квалификационный аттестат на право проведения энергоаудита и сертификации энергоэффективности зданий'}</p>
                            </div>
                            <div className={classes.item}>
                                <img src={require('../../img/ser2.jpg')} alt='sertificate'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'Квалификационный аттестат на право обследования инженерных систем здания' : 'Кваліфікаційний атестат на право обстеження інженерних систем будівлі'}</p>
                            </div>
                            <div className={classes.item}>
                                <img src={require('../../img/ser3.jpg')} alt='sertificate'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'Квалификационный сертификат на право разработки раздела «Энергоэффективность»' : 'Кваліфікаційний сертифікат на право розробки розділу «Енергоефективність»'}</p>
                            </div>
                            <div className={classes.item}>
                                <img src={require('../../img/ser4.jpg')} alt='sertificate'/>
                                <p className={classes.text}>{this.props.language === 'RU' ? 'Международный сертификат по энергоменджменту' : 'Міжнародний сертифікат з енергоменджменту'}</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.carousel}>
                        <h2 className={classes.title}>{this.props.language === 'RU' ? 'Нам доверяют:' : 'Нам довiряють:'}</h2>
                        <CarouselProvider
                            naturalSlideWidth={400}
                            naturalSlideHeight={250}
                            totalSlides={11}
                            visibleSlides={window.matchMedia('(max-width: 480px)').matches ? 2 : 4}
                            step={1}
                            infinite={true}
                            >
                            <Slider>
                                <Slide index={0}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/1.png')} />
                                </Slide>
                                <Slide index={1}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/2.png')} />
                                </Slide>
                                <Slide index={2}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/3.png')} />
                                </Slide>
                                <Slide index={3}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/4.png')} />
                                </Slide>
                                <Slide index={4}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/5.png')} />
                                </Slide>
                                <Slide index={5}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/6.png')} />
                                </Slide>
                                <Slide index={6}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/7.png')} />
                                </Slide>
                                <Slide index={7}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/8.png')} />
                                </Slide>
                                <Slide index={8}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/9.png')} />
                                </Slide>
                                <Slide index={9}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/10.png')} />
                                </Slide>
                                <Slide index={10}>
                                    <Image className={classes.slideImg} src={require('../../img/sertif/clientLogo/11.png')} />
                                </Slide>
                            </Slider>
                            <ButtonBack className={classes.buttonBack + ' ' + classes.button}><i className="fas fa-arrow-circle-left"></i></ButtonBack>
                            <ButtonNext className={classes.buttonNext + ' ' + classes.button}><i className="fas fa-arrow-circle-right"></i></ButtonNext>
                        </CarouselProvider>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language,
    }
}



export default connect(mapStateToProps, null)(Sertificate)
