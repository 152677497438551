import React, {Component} from 'react'
import classes from './Service.module.scss'
import GetCallBack from '../../components/GetCallBack/GetCallBack'
import Fade from 'react-reveal/Fade';

class Service extends Component {

    renderPars(props, clas) {
        return props.map((item, index) => {
            if (clas) {
                return(
                    <p key={index} className={classes.par + ' ' + classes.text}>{item}</p>
                )
            } else {
                return(
                    <p key={index} className={classes.par}>{item}</p>
                )
            }
        })
    }

    render () {
        return (
        <div className={classes.Service}>
            <div className='wrapper'>
                <h1 className={classes.mainTitle}>{this.props.language === 'RU' ? this.props.props.name : this.props.propsUkr.name}</h1>
                <p className={classes.titleText}>{this.props.language === 'RU' ? this.props.props.titleText : this.props.propsUkr.titleText}</p>
            </div>
            <img className={classes.bannerImg} alt='banner' src={require('../../img/serviceBanner-1-' + this.props.id + '.png')}/>
            <Fade>
            <div className={classes.advantages}>
                <div className='wrapper'>
                    <div className={classes.content}>
                        <div className={classes.ls}>
                            <h2 className={classes.title}>{this.props.language === 'RU' ? 
                            'Преимущества \nработы с компанией \nE–SAVE' : 
                            'Переваги\nроботи з підприємством \nE-SAVE'
                            }</h2>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.titleText}>{this.props.language === 'RU' ? this.props.props.advantages.titleText : this.props.propsUkr.advantages.titleText}</p>
                            {this.props.language === 'RU' ? 
                            this.renderPars(this.props.props.advantages.par) : 
                            this.renderPars(this.props.propsUkr.advantages.par)
                            }
                        </div>
                    </div>
                </div>
            </div>
            </Fade>
            <Fade>
            <div className={classes.why}>
                <div className='wrapper'>
                    <div className={classes.content}>
                        <div className={classes.absoluteBlock}>
                            <img className={classes.img1} alt='banner' src={require('../../img/servicePic1-' + this.props.id + '.png')}/>
                            <img className={classes.img2} alt='banner' src={require('../../img/servicePic2-' + this.props.id + '.png')}/>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.lsWhy}>
                                <h2 className={classes.title}>{this.props.language === 'RU' ? 
                            'Почему стоит \nвыбрать нас' : 
                            'Чому варто \nвибрати нас'
                            }</h2>
                            </div>
                            <div className={classes.rsWhy}>
                                <p className={classes.text}>{this.props.language === 'RU' ? this.props.props.why.titleText : this.props.propsUkr.why.titleText}</p>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.lsWhy}>
                                <h2 className={classes.title}>{this.props.language === 'RU' ? 
                            'Команда \ne-save это' : 
                            'Команда \ne-save це'
                            }</h2>
                            </div>
                            <div className={classes.rsWhy}>{this.props.language === 'RU' ? 
                            this.renderPars(this.props.props.why.par, true) : 
                            this.renderPars(this.props.propsUkr.why.par, true)
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <img className={classes.imgAbsolute} alt='banner' src={require('../../img/serviceBanner-2-' + this.props.id + '.png')}/>
            </div>
            </Fade>
            <Fade>
            <div className={classes.about}>
                <div className='wrapper'>
                    <div className={classes.content}>
                        <div className={classes.row}>
                                <div className={classes.ls}>
                                </div>
                                <div className={classes.rs}>
                                    <p className={classes.text}>{this.props.language === 'RU' ? this.props.props.about.titleText : this.props.propsUkr.about.titleText} </p>
                                    {this.props.language === 'RU' ? 
                                    this.renderPars(this.props.props.about.text) : 
                                    this.renderPars(this.props.propsUkr.about.text)
                                    }
                                </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.ls}>
                                <h2 className={classes.title}>{this.props.language === 'RU' ? this.props.props.whoNeed.titleText : this.props.propsUkr.whoNeed.titleText}</h2>
                            </div>
                            <div className={classes.rs}>
                                <p className={classes.text}>{this.props.language === 'RU' ? this.props.props.whoNeed.text : this.props.propsUkr.whoNeed.text}</p>
                                {this.props.language === 'RU' ? 
                                    this.renderPars(this.props.props.whoNeed.par) : 
                                    this.renderPars(this.props.propsUkr.whoNeed.par)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Fade>
            <Fade>
            <GetCallBack />
            </Fade>
        </div>
        )
    }
}

export default Service