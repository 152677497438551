import {CREATE_NEWS, RESET_NEWS_CREATION, UPLOAD_IMG} from './actionTypes'
import axios from '../../axios/axios'
import firebase from '../../axios/firebase'

export function createNews (item) {
    return {
        type: CREATE_NEWS,
        item
    }
}

export function resetNewsCreation () {
    alert ('Новость создана!')
    return {
        type: RESET_NEWS_CREATION
    }
}

export function finishCreateNews() {
    return async (dispatch, getState) => {
        const token = getState().auth.token
        if (getState().createNews.news.isTitle === true) {
            Object.entries(getState().news.news).map((item, index) => {
                if (item[1].isTitle === true) {
                    console.log(item)
                    item[1].isTitle = false
                    delete item[1].bigImgUrl1
                    delete item[1].bigImgUrl2
                    delete item[1].mediumImgUrl
                    delete item[1].shortImgUrl
                    axios.put(`/news/${item[0]}.json`, item[1], {
                        params: {auth: token}
                    })
                }
            })
        }
        await axios.post('/news.json', getState().createNews.news, {
            params: {auth: token}
        }).then(function (response) {
            let name = response.data.name
            const files = getState().createNews.img
            Object.values(files).forEach((item, index) => {
                if (index === 0) {
                    firebase.storage().ref('news/'+ 'big-img-' + name + '-0.png').put(item);
                } else if (index === 1) {
                    firebase.storage().ref('news/'+ 'big-img-' + name + '-1.png').put(item);
                } else if (index === 2) {
                    firebase.storage().ref('news/'+ 'title-img-' + name + '.png').put(item);
                } else if (index === 3) {
                    firebase.storage().ref('news/'+ 'medium-img-' + name + '.png').put(item);
                } else if (index === 4) {
                    firebase.storage().ref('news/'+ 'short-img-' + name + '.png').put(item);
                }
            })
        })
        
        dispatch(resetNewsCreation())
    }
}

export function uploadImg (img) {
    return {
        type: UPLOAD_IMG,
        img
    }
}