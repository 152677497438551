import React, {Component} from 'react'
import classes from './ProjectSingle.module.scss'
import GetCallBack from '../../components/GetCallBack/GetCallBack'

class ProjectSingle extends Component {

    renderPars(props) {
        return props.map((item, index) => {
            return(
                <p key={index} className={classes.text + ' ' + classes.par}>{item}</p>
            )
        })
    }

    render () {
        return (
        <div className={classes.ProjectSingle}>
            <div className='wrapper'><h1 className={classes.mainTitle}>{this.props.props.name}</h1> </div>
            <img className={classes.bannerImg} src={this.props.props.bigImgUrl} alt='bannerImg'/>
            <div className='wrapper'>
                <div className={classes.content}>
                    <div className={classes.row}>
                        <div className={classes.ls}>
                            <p className={classes.title}>клиент</p>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.title}>{this.props.props.client.titleText}</p>
                            <p className={classes.text}>{this.props.props.client.text}</p>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.ls}>
                            <p className={classes.title}>Услуга</p>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.title}>Были предоставлены следующие услуги:</p>
                            {this.renderPars(this.props.props.service.par)}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.ls}>
                            <p className={classes.title}>Период выполнения</p>
                        </div>
                        <div className={classes.rs}>
                            <p className={classes.title}>{this.props.props.date}</p>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.ls}>
                            <p className={classes.title}>итог работ</p>
                        </div>
                        <div className={classes.rs + ' ' + classes.result}>
                            <p className={classes.title}>результат</p>
                            <p className={classes.text}>{this.props.props.result.economyText}</p>
                            <p className={classes.title}>Экономия и окупаемость</p>
                            <p className={classes.text}>{this.props.props.result.resultText}</p>
                            <p className={classes.title}>Дальнейшие действия</p>
                            <p className={classes.text}>{this.props.props.result.nextStep}</p>
                        </div>
                    </div>
                </div>
            </div>
            <GetCallBack />
        </div>
        )
    }
}

export default ProjectSingle