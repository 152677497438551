import React, {Component} from 'react'
import classes from './Auth.module.scss'
import {connect} from 'react-redux'
import { NavLink } from 'react-router-dom'
import auth from '../../redux/actions/auth'
import onAdmin from '../../redux/actions/adminPage'


class Auth extends Component {

    state= {
        email: '',
        password: ''
    }

    componentDidMount () {
        this.props.onAdmin(true)
    }

    componentWillUnmount() {
        this.props.onAdmin(false)
    }

    submitHandler = event => {
        event.preventDefault()
      }

    onChangeHandler = (event) => {
        console.log(event.target.name, event.target.value)
        const name = event.target.name
        const value = event.target.value
        this.setState ({
            [name]: value
        })
    }

    loginHandler = async () => {
        this.props.auth(
            this.state.email,
            this.state.password,
            true
        )
    }

    render () {
        return (
            <div className={classes.Auth}>
                <div className='wrapper'>
                    <form className={classes.form} onSubmit={this.submitHandler}>
                        <label htmlFor='mail' value='E-mail'>E-mail</label>
                        <input name='email' id='mail' type='mail' required onChange={this.onChangeHandler}></input>
                        <label htmlFor='pass' value='Password'>Password</label>
                        <input name='password' id='pass' type='password' required onChange={this.onChangeHandler}></input>
                        <button type='submit' onClick={this.loginHandler}>Войти</button>
                    </form>
                    {this.props.isLogged === true
                    ?
                    <NavLink
                    className={classes.linkToAdmin}
                    to='/adminPanel'
                    >
                    Перейти в админ-панель
                    </NavLink>  
                    :
                        null
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isAdminPage: state.adminPage.isAdminPage,
        isLogged: !!state.auth.token
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onAdmin: (isAdmin) => dispatch(onAdmin(isAdmin)),
        auth: (email, password) => dispatch(auth(email, password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)