import React, {Component} from 'react'

class Banner extends Component {

    componentDidMount () {
        setTimeout(() => this.props.sliderHeader(), 1000);
    }
    render () {
        return (
            <div className={this.props.classes.Banner}>
                <h1 className={this.props.classes.titleBanner}>ЭНЕРГОАУДИТ</h1> 
                <p className={this.props.classes.textBanner}>Первый шаг к экономии энергоресурсов до 70%</p>
                <div
                    onClick={this.props.openModal}
                    className={this.props.classes.link}
                >
                        {this.props.language === 'RU' ? 'Получить предложение' : 'Отримати пропозицію'}
                </div>
            </div>
            )
        }
    }
export default Banner