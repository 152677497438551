import {CREATE_NEWS, RESET_NEWS_CREATION, UPLOAD_IMG} from '../actions/actionTypes'

const initialState = {
    news: [],
    img: []
}

export default function createNews(state = initialState, action) {
    switch (action.type) {
        case CREATE_NEWS:
            return {
                ...state, 
                news: action.item
            }
        case UPLOAD_IMG:
            console.log(action)
        return {
            ...state, 
            img: action.img
        }
        case RESET_NEWS_CREATION:
        return {
            ...state, 
            news: [],
            img: []
        }
        default: 
            return state
    }
}