import React, {Component} from 'react'
import classes from './About.module.scss'
import GetCallBack from '../../components/GetCallBack/GetCallBack'
import Fade from 'react-reveal/Fade'
import {connect} from 'react-redux'

class About extends Component {
    render () {
        return (
            <div className={classes.About}>
                
                <div className={classes.aboutHeader}>
                    <div className='wrapper'>
                        <div className={classes.titleText}>
                            <h1 className={classes.title}>{this.props.language === 'RU' ? 'о компании' : 'про компанiю'} </h1>
                            <p className={classes.text}>{this.props.language === 'RU' ? 
                            'Команда E-SAVE – это опытные энергоаудиторы, аккредитованные эксперты по энергосертификации зданий и обследованию инженерных систем, энергоменеджеры и аналитики в области энергоэффективного строительства.' : 
                            'Команда E-SAVE - це досвідчені енергоаудитори, акредитовані експерти по енергосертіфікаціі будівель і обстеження інженерних систем, енергоменеджери та аналітики в області енергоефективного будівництва.'
                            }</p>
                        </div>
                        <div className={classes.tabs}>
                            <p className={classes.col + ' ' + classes.colHs}>{this.props.language === 'RU' ? 
                            'ОСНОВНЫЕ НАПРАВЛЕНИЯ КОМПАНИИ:' : 
                            'ОСНОВНІ НАПРЯМКИ КОМПАНІЇ:'
                            }</p>
                            <p className={classes.col + ' ' + classes.colHs}>{this.props.language === 'RU' ? 
                            '•	Разработка энергетических сертификатов зданий\n•	Энергетический аудит зданий и промышленных предприятий\n•	Разработка раздела «Энергоэффективность» и энергетического паспорта здания' : 
                            '•	Розробка енергетичних сертифікатів будівель\n•	Енергетичний аудит будівель і промислових підприємств\n•	Розробка розділу «Енергоефективність» і енергетичного паспорта будівлі'
                            }</p>
                            {/* <p className={classes.col + ' ' + classes.colHs}>Комета Хейла-Боппа, несмотря на внешние воздействия, решает апогей. Как было показано выше, межзвездная матеpия доступна. Бесспорно, юлианская дата однородно отражает космический pадиотелескоп Максвелла, тем не менее, Дон Еманс включил в список всего 82-е Великие Кометы</p> */}
                            {/* <p className={classes.col + ' ' + classes.colLs}>Инженерные компетенции команды позволяют заказчикам реализовывать самые амбициозные цели. Свою миссию команда E-SAVE формулирует как создание инженерных решений для улучшения экономики, экологии и повышения энергоэффективности!</p>
                            <p className={classes.col + ' ' + classes.colLs}>Процесс обучения новым технологиям в проектировании и постоянный анализ глобальных и локальных трендов в энергоэффективности является частью нашей корпоративной культуры.</p> */}
                        </div>
                    </div>
                </div> 
                <Fade>
                <div className={classes.aboutBanner}>
                    <div className='wrapper'>
                        <div className={classes.content}>
                            <div className={classes.counters}>
                                <p className={classes.counter}>18</p>
                                <p className={classes.counterDesc}>{this.props.language === 'RU' ? 
                            'лет на рынке:' : 
                            'рокiв на ринку'
                            }</p>
                            </div>
                            <div className={classes.counters}>
                                <p className={classes.counter}>100+</p>
                                <p className={classes.counterDesc}>{this.props.language === 'RU' ? 
                            'выполненных проектов' : 
                            'виконаних проектiв'
                            }</p>
                            </div>
                            <div className={classes.counters}>
                                <p className={classes.counter}>40+</p>
                                <p className={classes.counterDesc}>{this.props.language === 'RU' ? 
                            'специалистов' : 
                            'специалiстiв'
                            }</p>
                            </div>
                        </div>
                    </div>
                </div>
                </Fade>
                <Fade>
                <div className={classes.team}>
                    <div className='wrapper'>
                        <div className={classes.content}>
                            <div className={classes.titleText}>
                                <h1 className={classes.title}>{this.props.language === 'RU' ? 
                            'наши сотрудники' : 
                            'наші співробітники'
                            }</h1>
                                <p className={classes.text}>{this.props.language === 'RU' ? 
                            'Наша корпоративная культура – это постоянный процесс обучения новым технологиям и анализ законодательной базы в сфере энергоэффективности и строительства.' : 
                            'Наша корпоративна культура - це постійний процес навчання новим технологіям і аналіз законодавчої бази в сфері енергоефективності та будівництва.'
                            }</p>
                            </div>
                            {/* <div className={classes.members}>
                                <div className={classes.row + ' ' + classes.rowHS}>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member01.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>founder, head of quality</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member02.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>founder, head of quality</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member03.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>head of production</p>
                                    </div>
                                </div>
                                <div className={classes.row + ' ' + classes.rowHS}>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member04.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>head of client's service</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member05.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>strategist</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member06.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>strategist</p>
                                    </div>
                                </div>
                                <div className={classes.row + ' ' + classes.rowLS}>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member01.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>founder, head of quality</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member02.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>founder, head of quality</p>
                                    </div>
                                </div>
                                <div className={classes.row + ' ' + classes.rowLS}>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member03.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>head of production</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member04.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>head of client's service</p>
                                    </div>
                                    
                                </div>
                                <div className={classes.row + ' ' + classes.rowLS}>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member05.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>strategist</p>
                                    </div>
                                    <div className={classes.card}>
                                        <img alt='member' src={require('../../img/member06.png')} />
                                        <p className={classes.name}>Иванов Иван Иванович</p>
                                        <p className={classes.pos}>strategist</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                </Fade>
                <Fade>
                <GetCallBack />
                </Fade>
                <Fade>
                <div className={classes.clients}>
                    <div className='wrapper'>
                        <h1 className={classes.title}>{this.props.language === 'RU' ? 
                            'Наши клиенты' : 
                            'Нашi клiєнти'
                            }</h1>
                        <div className={classes.logos}>
                            <img alt='logo' src={require('../../img/clientLogo01.png')} />
                            <img alt='logo' src={require('../../img/clientLogo02.png')} />
                            <img alt='logo' src={require('../../img/clientLogo03.png')} />
                            <img alt='logo' src={require('../../img/clientLogo04.png')} />
                            <img alt='logo' src={require('../../img/clientLogo05.png')} />
                            <img alt='logo' src={require('../../img/clientLogo06.png')} />
                            <img alt='logo' src={require('../../img/clientLogo07.png')} />
                            <img alt='logo' src={require('../../img/clientLogo08.png')} />
                            <img alt='logo' src={require('../../img/clientLogo09.png')} />
                            <img alt='logo' src={require('../../img/clientLogo10.png')} />
                            <div className={classes.eb + ' ' + classes.f}></div>
                            <div className={classes.eb}></div>
                        </div>
                    </div>
                </div>
                </Fade>
                <Fade>
                <div className={classes.documents}>
                    <div className='wrapper'>
                        <div className={classes.content}>
                            <div className={classes.ls}>
                                <h1 className={classes.title}>{this.props.language === 'RU' ? 
                            'Документы' : 
                            'Документи'
                            }</h1>
                            </div>
                            <div className={classes.rs}>
                                <div className={classes.document}>
                                    <a className={classes.link} href={require('../../pdf/EUREM.PDF')} target='_blank' rel="noopener noreferrer">
                                        <i className="fas fa-external-link-alt"> <p className={classes.pdf + ' ' + classes.pdfLS}>PDF</p></i>
                                        EUREM
                                    </a>
                                    <p className={classes.pdf + ' ' + classes.pdfHS}>PDF</p>
                                </div>
                                <div className={classes.document}>
                                    <a className={classes.link} href={require('../../pdf/АА000036.pdf')} target='_blank' rel="noopener noreferrer">
                                        <i className="fas fa-external-link-alt"> <p className={classes.pdf + ' ' + classes.pdfLS}>PDF</p></i>
                                        АА000036
                                    </a>
                                    <p className={classes.pdf + ' ' + classes.pdfHS}>PDF</p>
                                </div>
                                <div className={classes.document}>
                                    <a className={classes.link} href={require('../../pdf/АБ0000361.pdf')} target='_blank' rel="noopener noreferrer">
                                        <i className="fas fa-external-link-alt"> <p className={classes.pdf + ' ' + classes.pdfLS}>PDF</p></i>
                                        АБ0000361
                                    </a>
                                    <p className={classes.pdf + ' ' + classes.pdfHS}>PDF</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Fade>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        language: state.language.language
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About)