import {ADMIN_SET_STATE} from './actionTypes'

export function onAdminSetState(isAdmin) {
    return {
        type: ADMIN_SET_STATE,
        isAdmin
    }
}

export default function onAdmin(isAdmin) {
    return (dispatch) => {
        dispatch(onAdminSetState(isAdmin))
    }
}