import axios from 'axios'

export const getPic = axios.create({
    baseURL: 'https://esavesite-4a63c.appspot.com/'
})

export const getData = axios.create({
    baseURL: 'https://esavesite-4a63c.firebaseio.com/'
})

export default getData